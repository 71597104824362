<template>
    <!-- Option 1 & 3-->
    <div v-if="isVisible" class="loader-overlay">
      <div class="box">
        <div v-if="isVisible" class="loading">
            <div class="loading-bar"></div>
            <div class="loading-bar"></div>
            <div class="loading-bar"></div>
            <div class="loading-bar"></div>
            <div class="loading-bar"></div>
        </div>
        <span class="text" v-html="text"></span>
      </div>
    </div>
    <!-- Option 2 -->
  <!-- <div class="infinite-bar" v-if="isVisible">
    <div class="indeterminate"></div>
  </div> -->
  <!-- Option 4 -->
  <!-- <div v-if="isVisible" class="loader-overlay">
    <div class="box">
      <div class="loader"></div>
      <span class="text" v-html="text"></span>
    </div>
  </div> -->
</template>

<script>
export default {
  name: "route-Loader",
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ""
    },
  },
}
</script>

<style lang="scss" scoped>
// Option 1 & 3
.loader-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    cursor: pointer;
    span.text {
        display: inline-block;
        position: relative;
        top: 100%;
        left: 30%;
        transform: translate(-50%,-50%);
    }
}
.box {
  position: absolute;
  left: calc(50% - 60px);
  top: calc(50% - 100px);
  height: 150px;
  width: 150px;
}
.loading {
    height: 80px;
    width: 80px;
    position: absolute;
    left: calc(50% - 40px);
    top: calc(50% - 20px);
}
    .loading-bar {
        display: inline-block;
        width: 10px;
        height: 30px;
        border-radius: 4px;
        animation: loading 1.5s ease-in-out infinite;
        margin-left: 6px;
    }
    .loading-bar:nth-child(1) {
        background-color: $hark-dark-blue;
        animation-delay: 0;
    }
    .loading-bar:nth-child(2) {
        background-color: $hark-blue;
        animation-delay: 0.15s;
    }
    .loading-bar:nth-child(3) {
        background-color: $hark-dark-blue;
        animation-delay: .30s;
    }
    .loading-bar:nth-child(4) {
        background-color: $hark-grey;
        animation-delay: .45s;
    }
    .loading-bar:nth-child(5) {
        background-color: $tech-grey;
        animation-delay: .55s;
    }
@keyframes loading {
    0% {
      transform: scale(1);
    }
    20% {
      transform: scale(1, 3.2);
    }
    40% {
      transform: scale(1);
    }
}


// Option 2
// .infinite-bar {
//   position: relative;
//   height: 4px;
//   display: block;
//   width: 100%;
//   background-color: $hark-blue;
//   overflow: hidden;
// }
// .infinite-bar .indeterminate {
//   background-color: $hark-dark-blue;
// }
// .infinite-bar .indeterminate:before {
//   content: '';
//   position: absolute;
//   background-color: inherit;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   will-change: left, right;
//   -webkit-animation: indeterminate 2s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
//           animation: indeterminate 2s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
// }
// @-webkit-keyframes indeterminate {
//   0% {
//     left: -35%;
//     right: 100%;
//   }
//   60% {
//     left: 100%;
//     right: -90%;
//   }
//   100% {
//     left: 100%;
//     right: -90%;
//   }
// }
// @keyframes indeterminate {
//   0% {
//     left: -35%;
//     right: 100%;
//   }
//   60% {
//     left: 100%;
//     right: -90%;
//   }
//   100% {
//     left: 100%;
//     right: -90%;
//   }
// }

// Option 4
// .loader-overlay {
//     position: fixed;
//     width: 100%;
//     height: 100%;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     z-index: 10;
//     cursor: pointer;
//     span.text {
//         position: relative;
//         top: 100%;
//         left: 0;
//         transform: translate(-50%,-50%);
//     }
// }

// .box {
//   position: absolute;
//   left: calc(50% - 60px);
//   top: calc(50% - 100px);
//   height: 170px;
//   width: 190px;
// }
// .loader {
//   position: relative;
//   display: block;
//   top: 0;
//   left: 10%;
//   border: 16px solid #f3f3f3;
//   border-radius: 50%;
//   border-top: 16px solid $tech-grey;
//   border-right: 16px solid $hark-dark-blue;
//   border-bottom: 16px solid $hark-blue;
//   width: 120px;
//   height: 120px;
//   -webkit-animation: spin 2s linear infinite;
//   animation: spin 2s linear infinite;
// }

// @-webkit-keyframes spin {
//   0% {
//     -webkit-transform: rotate(0deg);
//   }
//   100% {
//     -webkit-transform: rotate(360deg);
//   }
// }

// @keyframes spin {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }

</style>