import Vue from "vue";

const state = {
  standardTagIcons: [
    {
      name: "smile",
      src: "https://cdn.mediasciencelabs.com/neuropoll/standard_smile.png"
    },
    {
      name: "thumbs-down",
      src:
        "https://cdn.mediasciencelabs.com/neuropoll/standard_Thumbs-Down-Circle.png"
    },
    {
      name: "thumbs-up",
      src:
        "https://cdn.mediasciencelabs.com/neuropoll/standard_Thumbs-Up-Circle.png"
    }
  ],
  standardTags: [
    {
      name: "Bookmark",
      description: "",
      icon: "far fa-bookmark",
      type: "standard",
      color: "989A99",
      order_by: 0,
      group: "Standard Library"
    },
    {
      name: "Positive",
      description: "",
      icon: "far fa-thumbs-up",
      type: "standard",
      color: "989A99",
      order_by: 0,
      group: "Standard Library"
    },
    {
      name: "Negative",
      description: "",
      icon: "far fa-thumbs-down",
      type: "standard",
      color: "989A99",
      order_by: 0,
      group: "Standard Library"
    },
    {
      name: "Wow!",
      description: "",
      icon: "fas fa-comment-alt-exclamation",
      type: "standard",
      color: "989A99",
      order_by: 0,
      group: "Standard Library"
    },
    {
      name: "Our Brand",
      description: "",
      icon: "fas fa-medal",
      type: "standard",
      color: "989A99",
      order_by: 0,
      group: "Standard Library"
    },
    {
      name: "Competitor",
      description: "",
      icon: "fas fa-boxing-glove",
      type: "standard",
      color: "989A99",
      order_by: 0,
      group: "Standard Library"
    },
    {
      name: "LOL",
      description: "",
      icon: "fas fa-grin-tears",
      type: "standard",
      color: "989A99",
      order_by: 0,
      group: "Standard Library"
    },
    {
      name: "Great Quote",
      description: "",
      icon: "fas fa-quote-right",
      type: "standard",
      color: "989A99",
      order_by: 0,
      group: "Standard Library"
    },
    {
      name: "For The Reel",
      description: "",
      icon: "fas fa-camera-movie",
      type: "standard",
      color: "989A99",
      order_by: 0,
      group: "Standard Library"
    },
    {
      name: "Probe",
      description: "",
      icon: "fas fa-search",
      type: "standard",
      color: "989A99",
      order_by: 0,
      group: "Standard Library"
    },
    {
      name: "Chat Tag",
      description: "",
      icon: "fas fa-comment-alt-edit",
      type: "standard",
      color: "989A99",
      order_by: 0,
      group: "Standard Library"
    },
    {
      name: "New Segment",
      description: "",
      icon: "fas fa-file-plus",
      type: "standard",
      color: "989A99",
      order_by: 0,
      group: "Standard Library"
    }

    // {
    //   name: "Bad Idea",
    //   description: "",
    //   icon: "fas fa-times",
    //   type: "standard",
    //   color: "9E0000",
    //   order_by: 0,
    //   group: "Standard Library"
    // },
    // {
    //   name: "Bookmark",
    //   description: "",
    //   icon: "far fa-bookmark",
    //   type: "standard",
    //   color: "508EE6",
    //   order_by: 0,
    //   group: "Standard Library"
    // },
    // {
    //   name: "Bored",
    //   description: "",
    //   icon: "far fa-meh",
    //   type: "standard",
    //   color: "989A99",
    //   order_by: 0,
    //   group: "Standard Library"
    // },
    // {
    //   name: "Brainstorming",
    //   description: "",
    //   icon: "fas fa-users",
    //   type: "standard",
    //   color: "68CCCA",
    //   order_by: 0,
    //   group: "Standard Library"
    // },
    // {
    //   name: "Dislike",
    //   description: "",
    //   icon: "far fa-angry",
    //   type: "standard",
    //   color: "D9081A",
    //   order_by: 0,
    //   group: "Standard Library"
    // },
    // {
    //   name: "Engaged",
    //   description: "",
    //   icon: "fas fa-exclamation",
    //   type: "standard",
    //   color: "68BC00",
    //   order_by: 0,
    //   group: "Standard Library"
    // },
    // {
    //   name: "Favorite",
    //   description: "",
    //   icon: "far fa-heart",
    //   type: "standard",
    //   color: "E22C74",
    //   order_by: 0,
    //   group: "Standard Library"
    // },
    // {
    //   name: "Like",
    //   description: "",
    //   icon: "far fa-smile",
    //   type: "standard",
    //   color: "3FA03C",
    //   order_by: 0,
    //   group: "Standard Library"
    // },
    // {
    //   name: "New Idea",
    //   description: "",
    //   icon: "far fa-lightbulb",
    //   type: "standard",
    //   color: "E2D32A",
    //   order_by: 0,
    //   group: "Standard Library"
    // },
    // {
    //   name: "Pros & Cons",
    //   description: "",
    //   icon: "fas fa-balance-scale",
    //   type: "standard",
    //   color: "0C797D",
    //   order_by: 0,
    //   group: "Standard Library"
    // }
  ],
  tag: null,
  tags: [],
  tagGroups: [],
  tagStub: {
    name: "",
    description: "",
    color: "",
    icon: "",
    type: "standard",
    group: "",
    upload_id: null
  }
};

const getters = {
  standardTagIcons: state => state.standardTagIcons,
  standardTags: state => state.standardTags,
  tag: state => state.tag,
  tags: state => state.tags,
  tagGroups: state => state.tagGroups,
  tagStub: state => state.tagStub
};

const actions = {
  createTag({ commit, dispatch }, tag) {
    return Vue.api.post("/tags", tag).then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          commit("insertTag", data);
          return data;
        } else {
          dispatch("errorHandler", response);
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },
  createTags({ commit, dispatch }, tags){
    return Vue.api.post("tags/batch", tags).then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          data.forEach(tag => {
            commit("insertTag", tag);
          });
          return data;
        } else {
          dispatch("errorHandler", response);
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },
  deleteTag({ commit, dispatch }, tag) {
    return Vue.api.delete("/tags/" + tag.id).then(
      response => {
        if (response.status === 204) {
          commit("removeTag", tag);
          dispatch("notifySuccess", "Tag successfully deleted");
        } else {
          dispatch("errorHandler", response);
        }
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  fetchTag({ commit, dispatch }, id) {
    return Vue.api.get("/tags/" + id).then(
      response => {
        if (response.status === 200) {
          let { data, error } = response.data;
          if (error) {
            dispatch("errorHandler", response);
          } else {
            commit("setTag", data);
          }
        } else {
          dispatch("errorHandler", response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  fetchTags({ commit, dispatch }, params) {
    return Vue.api.get("/tags", { params: params }).then(
      response => {
        if (response.status === 200) {
          let { data, error } = response.data;
          if (error) {
            dispatch("errorHandler", response);
          } else {
            commit("setPagination", response);
            commit("setTags", data);
          }
        } else {
          dispatch("errorHandler", response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  patchTag({ dispatch }, tag) {
    return Vue.api.patch("/tags/" + tag.id, tag.property).then(
      response => {
        if (response.status !== 204) {
          dispatch("errorHandler", response);
        }
        dispatch("notifySuccess", "Tag updated successfully.");
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  updateTag({ commit, dispatch }, tag) {
    return Vue.api.put("/tags/" + tag.id, tag).then(
      response => {
        if (response.status === 200) {
          let { data, error } = response.data;
          if (error) {
            dispatch("errorHandler", response);
          } else {
            commit("setTag", data);
          }
        } else {
          dispatch("errorHandler", response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  fetchTagGroups({ commit, dispatch }) {
    return Vue.api.get("/tag-groups").then(
      response => {
        if (response.status === 200) {
          let { data, error } = response.data;
          if (error) {
            dispatch("errorHandler", response);
          } else {
            commit("setTagGroups", data);
          }
        } else {
          dispatch("errorHandler", response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  }
};

const mutations = {
  insertTag(state, tag) {
    state.tags.push(tag);
  },
  removeTag(state, tag) {
    let idx = state.tags.findIndex(o => {
      return o.id === tag.id;
    });
    if (idx >= 0) {
      state.tags.splice(idx, 1);
    }
  },
  setTag(state, tag) {
    state.tag = tag;
  },
  setTags(state, tags) {
    state.tags = tags;
  },
  setTagIcon(state, icon) {
    state.tag.icon = icon;
  },
  setTagGroups(state, tagGroups) {
    state.tagGroups = tagGroups;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
