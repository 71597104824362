<template>
  <div>
    <transition
      enter-active-class="animated slideInLeft faster"
      leave-active-class="animated slideOutRight faster"
      mode="out-in"
    >
      <slot />
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "default-layout",
  data() {
    return {};
  },
  computed: {
    ...mapGetters([])
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions([])
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/colors";
</style>
