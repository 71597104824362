import axios from "axios";

const sessionApi = axios.create({
  baseURL: window._env_.VUE_APP_SESSION_API_URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    common: {},
    "Content-Type": "application/json"
  }
});

const MAX_REQUESTS_COUNT = 3;
const INTERVAL_MS = 10;
let PENDING_REQUESTS = 0;

/**
 * Axios Request Interceptor
 */
sessionApi.interceptors.request.use(function(config) {
  return new Promise((resolve, _reject) => {
    let interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS++;
        clearInterval(interval);
        resolve(config);
      }
    }, INTERVAL_MS);
  });
});

/**
 * Axios Response Interceptor
 */
sessionApi.interceptors.response.use(
  function(response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.resolve(response);
  },
  function(error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.reject(error);
  }
);

export { sessionApi };
