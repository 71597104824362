import { extend } from "vee-validate";
import { email, min, required, regex, max } from "vee-validate/dist/rules";

extend("email", email);
extend("min", min);
extend("max", max);
extend("minmax", {
  validate(value, args) {
    const length = value.length;

    return length >= args.min && length <= args.max;
  },
  params: ["min", "max"]
});
extend("required", required);
extend("regex", regex);
