import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

// Prevent duplicate navigation error
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "is-active",
  routes: [
    {
      path: "/",
      redirect: "/dashboard/scheduled"
    },
    {
      path: "/404",
      name: "not-found",
      component: () =>
        import(/* webpackChunkName: "not-found" */ "./views/NotFound.vue"),
      meta: {
        title: "Not Found | HARK",
        displayName: "Not Found",
        authRequired: false
      }
    },
    {
      path: "*",
      redirect: { name: "not-found" }
    },
    {
      path: "/login",
      name: "login",
      component: () =>
        import(/* webpackChunkName: "login" */ "./views/auth/AuthView.vue"),
      meta: {
        title: "Sign In | HARK",
        displayName: "Sign In",
        authRequired: false
      }
    },
    {
      path: "/signup",
      name: "signup",
      component: () =>
        import(/* webpackChunkName: "login" */ "./views/auth/AuthView.vue"),
      meta: {
        title: "Sign Up | HARK",
        displayName: "Sign Up",
        authRequired: false
      }
    },
    {
      path: "/forgot",
      name: "forgot",
      component: () =>
        import(/* webpackChunkName: "forgot" */ "./views/auth/Forgot.vue"),
      meta: {
        title: "Forgot Password | HARK",
        displayName: "Forgot Password",
        authRequired: false
      }
    },
    {
      path: "/reset/:resetPasswordId",
      name: "reset",
      component: () =>
        import(/* webpackChunkName: "reset" */ "./views/auth/Reset.vue"),
      meta: {
        title: "Forgot Password | HARK",
        displayName: "Forgot Password",
        authRequired: false
      }
    },
    {
      path: "/account",
      name: "account",
      component: () =>
        import(/* webpackChunkName: "account" */ "./views/account/Account.vue"),
      meta: {
        title: "Account | HARK",
        displayName: "Account",
        showHeader: true,
        authRequired: true
      }
    },
    {
      path: "/verify/email/:verificationId",
      name: "verify-email",
      component: () =>
        import(
          /* webpackChunkName: "verify-email" */ "./views/auth/signup/VerifyEmail.vue"
        ),
      meta: {
        title: "Verify Email | HARK",
        displayName: "Verify Email",
        authRequired: false
      }
    },
    {
      path: "/verify/message",
      name: "verify-message",
      component: () =>
        import(
          /* webpackChunkName: "verify-message" */ "./views/auth/signup/VerifyMessage.vue"
        ),
      meta: {
        title: "Verify Message | HARK",
        displayName: "Verify Message",
        authRequired: false
      }
    },
    {
      path: "/join/:uuid",
      name: "session-join",
      component: () =>
        import(
          /* webpackChunkName: "session-join" */ "./views/sessions/SessionJoin.vue"
        ),
      meta: {
        title: "Join Session | HARK",
        displayName: "Join Session",
        authRequired: false
      }
    },
    {
      path: "/dashboard/:page",
      name: "dashboard",
      component: () =>
        import(
          /* webpackChunkName: "dashboard" */ "./views/dashboard/Dashboard.vue"
        ),
      meta: {
        title: "Dashboard | HARK",
        displayName: "Dashboard",
        showHeader: true,
        authRequired: true
      }
    },
    {
      path: "/sessions/:id",
      name: "session-view",
      component: () =>
        import(
          /* webpackChunkName: "session-view" */ "./views/sessions/session-editor/SessionLoader.vue"
        ),
      meta: {
        title: "Edit Session | HARK",
        displayName: "Edit Session",
        layout: "fake-modal",
        authRequired: true
      }
    },
    {
      path: "/sessions/launch/:id",
      name: "session-launch",
      component: () =>
        import(
          /* webpackChunkName: "session-view" */ "./views/sessions/session-details/SessionDetails2.vue"
        ),
      meta: {
        title: "Session Details | HARK",
        displayName: "Session Details",
        layout: "fake-modal",
        authRequired: true
      }
    },
    {
      path: "/projects/:page",
      name: "projects",
      component: () =>
        import(
          /* webpackChunkName: "projects" */ "./views/projects/Projects.vue"
        ),
      meta: {
        title: "Projects | HARK",
        displayName: "Projects",
        showHeader: true,
        authRequired: true
      }
    },
    {
      path: "/projects/edit/:id",
      name: "project-view",
      component: () =>
        import(
          /* webpackChunkName: "project-view" */ "./views/projects/ProjectView.vue"
        ),
      meta: {
        title: "Edit Project | HARK",
        displayName: "Edit Project",
        layout: "fake-modal",
        authRequired: true
      }
    },
    {
      path: "/clients/:page",
      name: "clients",
      component: () =>
        import(/* webpackChunkName: "clients" */ "./views/clients/Clients.vue"),
      meta: {
        title: "Clients | HARK",
        displayName: "Clients",
        showHeader: true,
        authRequired: true
      }
    },
    {
      path: "/clients/edit/:id",
      name: "client-view",
      component: () =>
        import(
          /* webpackChunkName: "client-view" */ "./views/clients/ClientView.vue"
        ),
      meta: {
        title: "Edit Client | HARK",
        displayName: "Edit Client",
        layout: "fake-modal",
        authRequired: true
      }
    },
    {
      path: "/tags/:page",
      name: "tags",
      component: () =>
        import(/* webpackChunkName: "tags" */ "./views/tags/TagList.vue"),
      meta: {
        title: "Tags | HARK",
        displayName: "Tags",
        showHeader: true,
        authRequired: true
      }
    },
    {
      path: "/tags/edit/:id",
      name: "tag-view",
      component: () =>
        import(/* webpackChunkName: "tag-view" */ "./views/tags/TagView.vue"),
      meta: {
        title: "Edit Tag | HARK",
        displayName: "Edit Tag",
        layout: "fake-modal",
        authRequired: true
      }
    },
    {
      path: "/surveys",
      name: "surveys",
      component: () =>
        import(
          /* webpackChunkName: "surveys" */ "./views/surveys/SurveyList.vue"
        ),
      meta: {
        title: "Surveys | HARK",
        displayName: "Surveys",
        authRequired: true
      }
    },
    {
      path: "/surveys/:id",
      name: "survey-builder",
      component: () =>
        import(
          /* webpackChunkName: "survey-builder" */ "./views/surveys/SurveyBuilder.vue"
        ),
      meta: {
        title: "Survey Builder | HARK",
        displayName: "Survey Builder",
        authRequired: true
      }
    },
    {
      path: "/submit-feedback",
      name: "submit-feedback",
      component: () =>
        import(
          /* webpackChunkName: "submit-feedback" */ "./views/SubmitFeedback.vue"
        ),
      meta: {
        title: "Submit Feedback | HARK",
        displayName: "Submit Feedback",
        layout: "fake-modal",
        authRequired: true
      }
    }
  ]
});
