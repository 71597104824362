import Vue from "vue";

const state = {
  invitation: null
};

const getters = {
  invitation: state => state.invitation
};

const actions = {
  declineInvitation({ dispatch }, uuid) {
    return Vue.api.post("/invitations/uuid/" + uuid + "/decline").then(
      response => {
        let { error } = response.data;
        if (response.status === 204) {
          return true;
        } else {
          dispatch("errorHandler", response);
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },
  fetchInvitation({ commit, dispatch }, uuid) {
    return Vue.api.get("/invitations/uuid/" + uuid).then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          commit("setInvitation", data);
          return data;
        } else {
          dispatch("errorHandler", response);
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  }
};

const mutations = {
  setInvitation(state, invitation) {
    state.invitation = invitation;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
