import Vue from "vue";
import Vuex from "vuex";
import { isEmpty } from "lodash/core";
import { v4 as uuidv4 } from "uuid";
import * as Sentry from "@sentry/vue";
import router from "@/router";

// Modules
import abilityPlugin from "./modules/ability";
import aws from "./modules/aws";
import client from "./modules/client";
import invitation from "./modules/invitation";
import project from "./modules/project";
import role from "./modules/role";
import session from "./modules/session";
import survey from "./modules/survey";
import tag from "./modules/tag";
import user from "./modules/user";

Vue.use(Vuex);

Sentry.init({
  Vue: Vue,
  dsn: "https://glet_bc10fa96c7ade3df4c376d93f57b684e@gitlab.themediapanel.com/api/v4/error_tracking/collector/204",
  environment: process.env.NODE_ENV,
  release: `hark-client@${window._env_.VUE_APP_VERSION}`,
  logErrors: process.env.NODE_ENV === "development",
  ignoreErrors: [
    "Request failed with status code 401", // Part of normal auth/refresh process
    "Cannot set property 'scrollTop' of undefined" // Thrown by vue-ctk-date-time-picker
  ],
  autoSessionTracking: false
});

const state = {
  accessTimeout: 9 * 60 * 1000, // every 9 minutes, 1 minute before access token expires
  cdn: window._env_.VUE_APP_CDN,
  confirmModal: {
    action: "",
    args: {},
    buttonLabel: "Confirm",
    message: "",
    title: ""
  },
  editorURL: window._env_.VUE_APP_EDITOR_URL,
  extractorURL: window._env_.VUE_APP_EXTRACTOR_URL,
  fusionauthAppId: window._env_.VUE_APP_FUSIONAUTH_APP_ID,
  isDev: process.env.NODE_ENV === "development",
  isStaging: process.env.NODE_ENV === "staging",
  notifications: [],
  pagination: {
    entries: [],
    page_number: -1,
    page_size: -1,
    total_pages: -1,
    total_entries: -1
  },
  perPageValue: 10,
  rules: [],
  sentry: Sentry,
  transcriptionURL: window._env_.VUE_APP_TRANSCRIPT_URL,
  version: window._env_.VUE_APP_VERSION,
  routeLoader: {
    show : false,
    text: ""
  }
};

const getters = {
  accessTimeout: state => state.accessTimeout,
  cdn: state => state.cdn,
  confirmModal: state => state.confirmModal,
  editorURL: state => state.editorURL,
  extractorURL: state => state.extractorURL,
  fusionauthAppId: state => state.fusionauthAppId,
  isDev: state => state.isDev,
  isStaging: state => state.isStaging,
  notifications: state => state.notifications,
  pagination: state => state.pagination,
  perPageValue: state => state.perPageValue,
  sentry: state => state.sentry,
  transcriptionURL: state => state.transcriptionURL,
  version: state => state.version,
  routeLoader: state => state.routeLoader
};

const actions = {
  toggleRouteLoader({ commit }, params) {
    commit("setRouteLoader", params);
  },
  confirm({ commit }, args) {
    commit("setConfirmModal", args);
  },
  errorHandler({ dispatch, getters }, error) {
    if (getters.isDev) {
      console.error(error);
    }
    if (isEmpty(error)) {
      return;
    }
    let { data } = error?.response || error;
    // Redirect to 404 page
    if (error.status === 404) {
      router.push({ name: "not-found" });
    } else if (error.status === 400) {
      let message =
        error.data && error.data.error
          ? error.data.error
          : "Bad request. Please ensure that your data is formatted correctly and try again.";
      dispatch("notifyError", message);
    } else if (
      error.status === 401 ||
      (error.response && error.response.status === 401)
    ) {
      // router.push({ name: "sign-in" });
      // dispatch("notifyError", "Unauthorized.");
      // commit("decRefreshAttempts");
      // dispatch("attemptRefreshToken");
    }
    // Foreign Key Constraint
    else if (
      error.status === 409 ||
      (error.response && error.response.status === 409)
    ) {
      if (data.type === "ForeignKeyViolation") {
        dispatch(
          "notifyError",
          "Record may not be deleted because other items reference it."
        );
      } else if (data.type === "UniqueViolation") {
        dispatch(
          "notifyError",
          "A unique record containing this data already exists."
        );
      }
    }
    // Catchall error
    //
    // Removed temporarily for #349
    //
    // else {
    //   dispatch("notifyError", "An unknown error has occured.");
    // }
    /*if (error.status === 500 && data.error) {
      // Foreign Key Constraint
      if (data.error.detail.indexOf("still referenced") >= 0) {
        dispatch(
          "notifyError",
          "Record may not be deleted because other items reference it."
        );
      }
    }*/
  },
  deleteNotification({ commit }, id) {
    commit("removeNotification", id);
  },
  notify({ commit }, { type, message, action }) {
    let notification = {
      id: uuidv4(),
      type: type,
      message: message,
      action: action
    };
    commit("insertNotification", notification);
    setTimeout(() => commit("removeNotification", notification.id), 8000);
  },
  notifyError({ dispatch }, message) {
    dispatch("notify", { type: "is-danger", message: message });
  },
  notifyInfo({ dispatch }, message) {
    dispatch("notify", { type: "is-info", message: message });
  },
  notifySuccess({ dispatch }, message) {
    dispatch("notify", { type: "is-success", message: message });
  },
  notifySuccessAction({ dispatch }, { message, action }) {
    dispatch("notify", {
      type: "is-success",
      message: message,
      action: action
    });
  },
  notifyWarning({ dispatch }, message) {
    dispatch("notify", { type: "is-warning", message: message });
  },
  emailFeedback({ dispatch }, feedback){
    Vue.api.post("/feedback", feedback).then(
      response => {
        if (response.status !== 204) {
          throw new Error(response);
        }
        dispatch("notifySuccess", "Feedback submitted.");
        router.back();
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        dispatch("notifyError", "Failed to submit feedback.");
        throw error;
      }
    );
  },
  fetchPerPageValue({ commit }) {
    const value = JSON.parse(localStorage.getItem("perPageValue"));
    if (value) {
      commit("setPerPageValue", value);
    }
  }
};

const mutations = {
  setRouteLoader(state, params){
    state.routeLoader.show = params.show;
    state.routeLoader.text = params.text;
  },
  clearConfirmModal(state) {
    state.confirmModal.action = "";
    state.confirmModal.args = {};
    state.confirmModal.buttonLabel = "Confirm";
    state.confirmModal.message = "";
    state.confirmModal.title = "";
  },
  setConfirmModal(state, confirm) {
    if (!isEmpty(confirm.action)) {
      state.confirmModal.action = confirm.action;
    }
    if (!isEmpty(confirm.args)) {
      state.confirmModal.args = confirm.args;
    }
    if (!isEmpty(confirm.buttonLabel)) {
      state.confirmModal.buttonLabel = confirm.buttonLabel;
    }
    if (!isEmpty(confirm.message)) {
      state.confirmModal.message = confirm.message;
    }
    if (!isEmpty(confirm.title)) {
      state.confirmModal.title = confirm.title;
    }
  },
  insertNotification(state, notification) {
    let idx = state.notifications.findIndex(o => {
      return o.message === notification.message;
    });
    if (idx < 0) {
      state.notifications.unshift(notification);
    }
  },
  removeNotification(state, id) {
    let idx = state.notifications.findIndex(o => {
      return o.id === id;
    });
    if (idx >= 0) {
      state.notifications.splice(idx, 1);
    }
  },
  setPagination(state, response) {
    state.pagination = {
      page_number: parseInt(response.headers["x-page-number"]),
      per_page: parseInt(response.headers["x-page-size"]),
      total: parseInt(response.headers["x-total"]),
      total_pages: parseInt(response.headers["x-total-pages"])
    };
  },

  // Vue-CASL Hook
  createCASL(state, session) {
    state.rules = session.rules;
    // console.log('rules', session.rules);
  },
  setPerPageValue(state, value) {
    state.perPageValue = value;
  }
};

export default new Vuex.Store({
  plugins: [abilityPlugin],
  state,
  getters,
  actions,
  mutations,
  modules: {
    aws,
    client,
    invitation,
    project,
    role,
    session,
    survey,
    tag,
    user
  }
});
