import Vue from "vue";
import { isEmpty } from "lodash/core";

const state = {
  archivedProjects: [],
  project: null,
  projects: []
};

const getters = {
  archivedProjects: state => state.archivedProjects,
  project: state => state.project,
  projects: state => state.projects,
};

const actions = {
  createProject({ commit, dispatch }, project) {
    return Vue.api.post("/projects", project).then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          commit("insertProject", data);
          return data;
        } else {
          dispatch("errorHandler", response);
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },
  deleteProject({ commit, dispatch }, project) {
    return Vue.api.delete("/projects/" + project.id).then(
      response => {
        if (response.status === 204) {
          commit("removeProject", project);
          dispatch("notifySuccess", "Project deleted successfully.");
        } else {
          dispatch("errorHandler", response);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },
  fetchArchivedProjects({ commit, dispatch }) {
    return Vue.api.get("/projects/archived").then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          commit("setArchivedProjects", data);
          return data;
        } else {
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        return Promise.reject(error);
      }
    );
  },
  fetchProject({ commit, dispatch }, id) {
    return Vue.api.get("/projects/" + id).then(
      response => {
        if (response.status === 200) {
          let { data, error } = response.data;
          if (error) {
            dispatch("errorHandler", response);
          } else {
            commit("setProject", data);
          }
        } else {
          dispatch("errorHandler", response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  fetchProjects({ commit, dispatch }, params) {
    return Vue.api.get("/projects", { params: params }).then(
      response => {
        if (response.status === 200) {
          let { data, error } = response.data;
          if (error) {
            dispatch("errorHandler", response);
          } else {
            commit("setPagination", response);
            commit("setProjects", data);
            return data;
          }
        } else {
          dispatch("errorHandler", response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  patchProject({ dispatch }, project) {
    return Vue.api.patch("/projects/" + project.id, project.property).then(
      response => {
        if (response.status != 204) {
          dispatch("errorHandler", response);
        }
        dispatch("notifySuccess", "Project updated successfully.");
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  updateProject({ commit, dispatch }, project) {
    return Vue.api.put("/projects/" + project.id, project).then(
      response => {
        if (response.status === 200) {
          let { data, error } = response.data;
          if (error) {
            dispatch("errorHandler", response);
          } else {
            commit("setProject", data);
          }
        } else {
          dispatch("errorHandler", response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  }
};

const mutations = {
  insertProject(state, project) {
    state.projects.push(project);
  },
  removeProject(state, project) {
    let idx = state.projects.findIndex(o => {
      return o.id === project.id;
    });
    if (idx >= 0) {
      state.projects.splice(idx, 1);
    }
  },
  setArchivedProjects(state, projects) {
    state.archivedProjects = projects;
  },
  setProject(state, project) {
    state.project = project;
  },
  setProjects(state, projects) {
    state.projects = projects;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};