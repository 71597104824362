import Vue from "vue";
import { isEmpty, has, map } from "lodash/core";
import countBy from "lodash/countBy";
import get from "lodash/get";
import moment from "moment-timezone";
import store from "../../store";
import { getUsername } from "@/lib/utils";
import { saveAs } from "file-saver";

import Papa from "papaparse";
const Paddle = window.Paddle;

function formattedDiff(sessionStart, timestamp) {
  let start = moment(sessionStart);
  let ts = moment(timestamp);
  let diff = ts.diff(start);
  let duration = moment.duration(diff);
  let isNegative = (diff < 0) ? "-" : "";
  let hours = Math.abs(duration.hours());
  hours = hours < 10 ? `0${hours}` : hours;
  let minutes = Math.abs(duration.minutes());
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  let seconds = Math.abs(duration.seconds());
  seconds = seconds < 10 ? `0${seconds}` : seconds;
  return `${isNegative}${hours}:${minutes}:${seconds}`;
}

const state = {
  available: {
    clients: [],
    tags: [],
    tagGroups: []
  },
  palettes: {
    cat10: [
      "#1f77b4",
      "#ff7f0e",
      "#2ca02c",
      "#d62728",
      "#9467bd",
      "#8c564b",
      "#e377c2",
      "#7f7f7f",
      "#bcbd22",
      "#17becf"
    ],
    material: [
      "#E65100",
      "#F4511E",
      "#EF5350",
      "#EC407A",
      "#BA68C8",
      "#9575CD",
      "#7986CB",
      "#1a237e",
      "#1565c0",
      // "#1E88E5",
      // "#0288D1",
      "#0097A7",
      "#009688",
      "#43A047",
      "#558B2F",
      "#827717",
      "#A1887F",
      "#757575"
    ],
    tableau10: [
      "#4e79a7",
      "#f28e2c",
      "#e15759",
      "#76b7b2",
      "#59a14f",
      "#edc949",
      "#af7aa1",
      "#ff9da7",
      "#9c755f",
      "#bab0ab"
    ]
  },
  sourceLanguages: [],
  targetLanguages: [],
  selectedSession: false,
  participant: null,
  participants: [],
  // projects: [],
  session: null,
  sessions: [],
  sessionsCounts: {
    scheduled: 0,
    unscheduled: 0,
    active: 0,
    past: 0,
    archived: 0
  },
  sessionClips: [],
  sessionInvitations: [],
  sessionServer: window._env_.VUE_APP_SESSION_CLIENT_URL,
  sessionUsers: [],
  sessionUsersNew: [],
  sessionVideos: [],
  invitees: []
};

const getters = {
  available: state => state.available,
  availableClients: state => state.available.clients,
  availableTags: state => state.available.tags,
  availableTagGroups: state => state.available.tagGroups,
  palettes: state => state.palettes,
  sourceLanguages: state => state.sourceLanguages,
  targetLanguages: state => state.targetLanguages,
  session: state => state.session,
  sessions: state => state.sessions,
  sessionsCounts: state => state.sessionsCounts,
  sessionClips: state => state.sessionClips,
  sessionInvitations: state => state.sessionInvitations,
  sessionServer: state => state.sessionServer,
  sessionUsers: state => state.sessionUsers,
  sessionUsersNew: state => state.sessionUsersNew,
  sessionVideos: state => state.sessionVideos,
  invitees: state => state.invitees,
  sessionUserDetails: ({ session }, { sessionInvitations, sessionUsers }) => {
    const usersList = []
      .concat(
        sessionInvitations.map(s => ({ ...s, hasAcceptedInvite: false })),
        sessionUsers.map(s => ({ ...s, hasAcceptedInvite: true }))
      )
      .map(user => {
        const userColor = session?.metadata?.userColors?.[user.email] || "grey";
        return {
          ...user,
          role: get(user, "session.role.name", ""),
          roleId: get(user, "session.role.id", ""),
          userColor
        };
      });

    return usersList;
  }
};

const actions = {
  showSessionDetailModal({ commit }, sessionObj) {
    commit("showSessionDetailModal", sessionObj);
  },
  purchaseSessions({ dispatch, rootGetters }, fetchUnscheduled) {
    let params = {
      orderBy: "appointed_timestart",
      orderByDirection: "asc",
      page: 0,
      pageSize: 10,
      activeFilter: false,
      projectFilter: "",
      typeFilter: "unscheduled"
    };
    Paddle.Checkout.open({
      product: 581264,
      email: rootGetters.user.email,
      passthrough: { userId: rootGetters.user.id },
      successCallback: function(data) {
        const userData = {
          id: rootGetters.user.id,
          data: {
            paddle_id: data.user.id
          }
        };
        dispatch("patchUser", userData);
        if (fetchUnscheduled) {
          dispatch("fetchSessions", params);
        }
        dispatch("fetchSessionsCounts");
      }
    });
  },
  createSession({ commit, dispatch }, session) {
    return Vue.api.post("/sessions", session).then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          commit("insertSession", data);
          return data;
        } else {
          dispatch("errorHandler", response);
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },
  createPaddleSession({ commit, dispatch }) {
    return Vue.api.post("/sessions/paddle").then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          commit("insertSession", data);
          return data;
        } else {
          dispatch("errorHandler", response);
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },
  createSessions({ commit, dispatch }, sessions) {
    return Vue.api.post("/sessions/batch", sessions).then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          commit("insertSessions", data);
          return data;
        } else {
          dispatch("errorHandler", response);
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },
  fetchSession({ commit, dispatch }, id) {
    return Vue.api.get("/sessions/" + id).then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          commit("createCASL", response.data);
          commit("setSession", data);
          return response;
        } else {
          dispatch("errorHandler", response);
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        return Promise.reject(error);
      }
    );
  },
  fetchSessions({ commit, dispatch }, params) {
    return Vue.api.get("/sessions", { params: params }).then(
      response => {
        if (response.status === 200) {
          let { data, error } = response.data;
          if (error) {
            dispatch("errorHandler", response);
          } else {
            commit("setPagination", response);
            commit("setSessions", data);
          }
        } else {
          dispatch("errorHandler", response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  fetchSessionsCounts({ commit, dispatch }, params){
    return Vue.api.get("/sessions/counts", { params: params}).then(
      response => {
        if (response.status === 200) {
          let { data, error } = response.data;
          if (error) {
            dispatch("errorHandler", response);
          } else {
            commit("setSessionsCounts", data);
          }
        } else {
          dispatch("errorHandler", response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  checkSessionsDuplicates({ commit, dispatch }, data){
    return Vue.api.get(`/sessions/${data.id}/duplicate-check/${data.name}`).then(
      response => {
        if (response.status === 200) {
          let { data, error } = response.data;
          if (error) {
            dispatch("errorHandler", response);
          }
        } else {
          dispatch("errorHandler", response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  patchSession({ dispatch }, session) {
    return Vue.api.patch("/sessions/" + session.id, session.property).then(
      response => {
        if (response.status !== 204) {
          dispatch("errorHandler", response);
        }
        dispatch("notifySuccess", "Session updated successfully.");
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  updateSession({ commit, dispatch }, session) {
    return Vue.api.put("/sessions/" + session.id, session.property).then(
      response => {
        if (response.status === 200) {
          let { data, error } = response.data;
          if (error) {
            dispatch("errorHandler", response);
          } else {
            commit("setSession", data);
          }
        } else {
          dispatch("errorHandler", response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  deleteSession({ commit, dispatch }, session) {
    return Vue.api.delete("/sessions/" + session.id).then(
      response => {
        if (response.status === 204) {
          commit("removeSession", session);
          dispatch("notifySuccess", "Session deleted successfully.");
        } else {
          dispatch("errorHandler", response);
        }
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },

  // Session Clients
  createSessionClient({ commit, dispatch }, data) {
    return Vue.api.post("/sessions/" + data.session_id + "/clients", data).then(
      response => {
        if (response.status === 200) {
          let { data, error } = response.data;
          if (error) {
            dispatch("errorHandler", response);
          } else {
            commit("insertSessionClient", data);
          }
        } else {
          dispatch("errorHandler", response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  deleteSessionClient({ commit, dispatch }, data) {
    return Vue.api
      .delete("/sessions/" + data.session_id + "/clients/" + data.client_id)
      .then(
        response => {
          if (response.status === 204) {
            commit("removeSessionClient", data);
          } else {
            dispatch("errorHandler", response);
          }
          return response;
        },
        error => {
          dispatch("errorHandler", error);
          return error;
        }
      );
  },

  // Session Invitations
  createSessionInvitations({ commit, dispatch }, data) {
    return Vue.api
      .post("/sessions/" + data.id + "/invitations/batch", data.invitations)
      .then(
        response => {
          let { data, error } = response.data;
          if (response.status === 200) {
            commit("insertSessionInvitations", data);
            return data;
          } else {
            dispatch("errorHandler", response);
            throw new Error(error);
          }
        },
        error => {
          dispatch("errorHandler", error);
          throw error;
        }
      );
  },
  fetchSessionInvitations({ commit, dispatch }, id) {
    return Vue.api.get("/sessions/" + id + "/invitations").then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          commit("setSessionInvitations", data);
          return data;
        } else {
          dispatch("errorHandler", response);
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },
  patchSessionInvitation({ dispatch }, data) {
    let url = `/sessions/${data.session_id}/invitations/${data.invitation_id}`;
    return Vue.api.patch(url, data.property).then(
      response => {
        if (response.status !== 204) {
          throw new Error(response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },
  resendSessionInvitation({ dispatch }, data) {
    let url = `/sessions/${data.session_id}/invitations/${data.invitation_id}/resend`;
    return Vue.api.post(url, {}).then(
      response => {
        if (response.status !== 204) {
          throw new Error(response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return Promise.reject(error);
      }
    );
  },

  // Session Tags
  createSessionTag({ commit, dispatch }, data) {
    return Vue.api.post("/sessions/" + data.session_id + "/tags", data).then(
      response => {
        if (response.status === 200) {
          let { data, error } = response.data;
          if (error) {
            dispatch("errorHandler", response);
          } else {
            commit("insertSessionTag", data);
          }
        } else {
          dispatch("errorHandler", response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  deleteSessionTag({ commit, dispatch }, data) {
    return Vue.api
      .delete("/sessions/" + data.session_id + "/tags/" + data.tag_id)
      .then(
        response => {
          if (response.status === 204) {
            commit("removeSessionTag", data);
          } else {
            dispatch("errorHandler", response);
          }
          return response;
        },
        error => {
          dispatch("errorHandler", error);
          return error;
        }
      );
  },
  batchCreateSessionTags({ commit, dispatch }, sessionTagsData) {
    let { id, sessionTags, tags } = sessionTagsData;
    return Vue.api.post("/sessions/" + id + "/tags/batch", sessionTags).then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          // Update tags so session view gets updated instead of re-fetching session
          commit("insertSessionTags", tags);
          return data;
        } else {
          dispatch("errorHandler", response);
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },

  // Session Uploads
  createSessionUpload({ dispatch }, data) {
    return Vue.api.post("/sessions/" + data.session_id + "/uploads", data).then(
      response => {
        if (response.status === 200) {
          let { data, error } = response.data;
          if (error) {
            dispatch("errorHandler", response);
          } else {
            dispatch("fetchSession", data.session_id);
          }
        } else {
          dispatch("errorHandler", response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  deleteSessionUpload({ commit, dispatch }, data) {
    return Vue.api
      .delete("/sessions/" + data.session_id + "/uploads/" + data.upload_id)
      .then(
        response => {
          if (response.status === 204) {
            commit("removeSessionUpload", data);
          } else {
            dispatch("errorHandler", response);
          }
          return response;
        },
        error => {
          dispatch("errorHandler", error);
          return error;
        }
      );
  },

  // Session Videos
  fetchSessionVideo({ dispatch }, videoData) {
    let { session, video } = videoData;
    let options = { responseType: "blob" };
    return Vue.sessionApi
      .get("/s/" + session.uuid + "/videos/" + video, options)
      .then(
        response => {
          if (response.status === 200) {
            let blob = new Blob([response.data], { type: "video/mp4" });
            saveAs(blob, video);
            return "File downloading...";
          } else {
            dispatch("errorHandler", response);
            throw new Error("Error downloading biometric data.");
          }
        },
        error => {
          dispatch("errorHandler", error);
          throw error;
        }
      );
  },
  fetchSessionVideos({ commit, dispatch }, session) {
    return Vue.sessionApi.get("/s/" + session.uuid + "/videos").then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          commit("setSessionVideos", data);
          return data;
        } else {
          dispatch("errorHandler", response);
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },

  // Downloads from session server
  downloadSessionDataAsCSV({ dispatch }, data) {
    let { metric, session } = data;
    return Vue.sessionApi.get("/s/" + session.uuid + "/metric/" + metric).then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          const csv = Papa.unparse(data);
          let blob = new Blob([csv], { type: "text/csv;charset=utf-8" });

          let title = session.name;
          switch (metric) {
            case "eda":
              title += "_Biometrics_";
              break;
            case "message":
              title += "_Messages_";
              break;
            case "tag":
              title += "_Tags_";
              break;
          }
          title += moment().toISOString() + ".csv";
          saveAs(blob, title);
          return "File downloading...";
        } else {
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },
  downloadAllAsCSV({ dispatch }, session) {
    return Vue.sessionApi.get("/s/" + session.uuid + "/all-metrics").then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          const formattedData = [];
          data.forEach((datum) => {
            let relativeTime = formattedDiff(session.timestart, datum.timestamp);
            let formattedDatum = {
              timestamp: datum.timestamp,
              relativeTime: relativeTime,
              type: "",
              user: "",
              value: ""
            };
            if (datum.metric.indexOf(":mindprober") >= 0) {
              let type = datum?.value?.measure?.name || "";
              let value = formattedDatum.value = datum?.value?.measure?.value || "";
              if (type === "gsr") {
                formattedDatum.user = datum?.value?.testerID || "";
                formattedDatum.type = "gsr";
                formattedDatum.value = value;
              } else if (type === "hr_bpm") {
                formattedDatum.user = datum?.value?.testerID || "";
                formattedDatum.type = "heartrate-bpm";
                formattedDatum.value = value;
              } else if (type === "hr_ibi") {
                formattedDatum.user = datum?.value?.testerID || "";
                formattedDatum.type = "heartrate-ibi";
                formattedDatum.value = value;
              } else if (type === "ars") {
                formattedDatum.user = datum?.value?.testerID || "";
                formattedDatum.type = "rating";
                if (value !== 0) {
                  formattedDatum.value = value;
                }
              }
              if (formattedDatum.value) {
                formattedData.push(formattedDatum);
              }
            } else if (datum.metric.indexOf("message") >= 0) {
              formattedDatum.user = getUsername(datum?.value?.sender || datum?.value?.user);
              formattedDatum.value = datum.value.text;
              formattedDatum.type = datum.value.type;
              formattedData.push(formattedDatum);
            } else if (datum.metric.indexOf("tag") >= 0) {
              let username = (datum.value?.tag?.type === "keyword" || datum.value?.tag?.type === "sentiment")
                ? "HARK AI"
                : getUsername(datum?.value?.sender || datum?.value?.user);
              formattedDatum.user = username;
              formattedDatum.value = datum?.value?.tag?.name;
              formattedDatum.type = "tag";
              formattedData.push(formattedDatum);
            } else if (datum.metric.indexOf("transcript") >= 0) {
              let language = session.source_languages[0]?.code;
              formattedDatum.user = datum.value.participant.name;
              formattedDatum.value = datum.value.transcript[language];
              formattedDatum.type = "transcript";
              formattedData.push(formattedDatum);
            }
          });
          const csv = Papa.unparse(formattedData);
          let blob = new Blob([csv], { type: "text/csv;charset=utf-8" });

          let title =
            session.name +
            "_All-Data Log_" +
            moment(session.appointed_timestart).toISOString() +
            ".csv";
          saveAs(blob, title);
          return "File downloading...";
        } else {
          dispatch("errorHandler", response);
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },
  downloadEDAAsCSV({ dispatch }, session) {
    return Vue.sessionApi.get("/s/" + session.uuid + "/metric/eda").then(
      response => {
        if (response.status === 200) {
          let blob = new Blob([response.data], {
            type: "application/octet-stream"
          });

          let title =
            session.name +
            "_Biometrics_" +
            moment(session.appointed_timestart).toISOString() +
            ".csv";
          saveAs(blob, title);
          return "File downloading...";
        } else {
          dispatch("errorHandler", response);
          throw new Error("Error downloading biometric data.");
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },
  downloadEmotionsAsCSV({ dispatch }, session) {
    return Vue.sessionApi.get("/s/" + session.uuid + "/metric/emotion:affectiva").then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          const formattedData = [];
          data.forEach((datum) => {
            // Should the name for keyword tags be blank or something like "HARK Keyword", "Auto", "AI", etc?
            let val = datum?.value || {};
            let videoHeight = val.video?.height || "";
            let videoWidth = val.video?.width || "";
            let faces = val.faces || [];
            // Make a new row for each face
            for (let i=0; i<faces.length; i++) {
              let face = faces[i];
              let formattedDatum = {
                timestamp: datum.timestamp,
                username: "HARK",
                videoWidth: videoWidth,
                videoHeight: videoHeight
              };
              let box = face.box;
              delete face.box;
              formattedDatum = {
                ...formattedDatum,
                ...box,
                ...face
              };
              formattedData.push(formattedDatum);
            }
            // const formattedDatum = {
            //   timestamp: datum.timestamp,
            //   username: "HARK",
            //   videoWidth: videoWidth,
            //   videoHeight: videoHeight,
            //   emotion: JSON.stringify(faces)
            // };
            // formattedData.push(formattedDatum);
          });
          const csv = Papa.unparse(formattedData);
          let blob = new Blob([csv], { type: "text/csv;charset=utf-8" });

          let title =
            session.name +
            "_Emotion Log_" +
            moment(session.appointed_timestart).toISOString() +
            ".csv";
          saveAs(blob, title);
          return "File downloading...";
        } else {
          dispatch("errorHandler", response);
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },
  downloadMessagesAsCSV({ dispatch }, session) {
    return Vue.sessionApi.get("/s/" + session.uuid + "/metric/message").then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          const formattedData = [];
          data.forEach((datum) => {
            let relativeTime = formattedDiff(session.timestart, datum.timestamp);
            const formattedDatum = {
              timestamp: datum.timestamp,
              relativeTime: relativeTime,
              username: getUsername(datum?.value?.sender || datum?.value?.user),
              message: datum.value.text,
              type: datum.value.type
            };
            formattedData.push(formattedDatum);
          });
          const csv = Papa.unparse(formattedData);
          let blob = new Blob([csv], { type: "text/csv;charset=utf-8" });

          let title =
            session.name +
            "_Chat Log_" +
            moment(session.appointed_timestart).toISOString() +
            ".csv";
          saveAs(blob, title);
          return "File downloading...";
        } else {
          dispatch("errorHandler", response);
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },
  downloadMindproberBiometricsAsCSV({ dispatch }, session) {
    return Vue.sessionApi.get("/s/" + session.uuid + "/metric/mindprober").then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          const formattedData = [];
          data.forEach((datum) => {
            let relativeTime = formattedDiff(session.timestart, datum.timestamp);
            let type = datum?.value?.measure?.name || "";
            let formattedDatum = {
              timestamp: datum.timestamp,
              relativeTime: relativeTime,
              respondent: datum?.value?.testerID || ""
            };
            let value = formattedDatum.value = datum?.value?.measure?.value || "";
            if (type === "gsr") {
              formattedDatum.type = "gsr";
              formattedDatum.value = value;
            } else if (type === "hr_bpm") {
              formattedDatum.type = "heartrate-bpm";
              formattedDatum.value = value;
            } else if (type === "hr_ibi") {
              formattedDatum.type = "heartrate-ibi";
              formattedDatum.value = value;
            } else if (type === "ars") {
              formattedDatum.type = "rating";
              if (value !== 0) {
                formattedDatum.value = value;
              }
            }
            if (formattedDatum.value) {
              formattedData.push(formattedDatum);
            }
          });
          const csv = Papa.unparse(formattedData);
          let blob = new Blob([csv], { type: "text/csv;charset=utf-8" });

          let title =
            session.name +
            "_Biometrics Log_" +
            moment(session.appointed_timestart).toISOString() +
            ".csv";
          saveAs(blob, title);
          return "File downloading...";
        } else {
          dispatch("errorHandler", response);
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },
  downloadTagsAsCSV({ dispatch }, session) {
    return Vue.sessionApi.get("/s/" + session.uuid + "/metric/tag").then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          const formattedData = [];
          data.forEach((datum) => {
            // Should the name for keyword tags be blank or something like "HARK Keyword", "Auto", "AI", etc?
            let username = "";
            if (datum.value?.tag?.type === "keyword" || datum.value?.tag?.type === "sentiment") {
              username = "HARK AI";
            } else {
              username = getUsername(datum?.value?.sender || datum?.value?.user);
            }
            const formattedDatum = {
              timestamp: datum.timestamp,
              username: username,
              tag: datum.value.tag.name
            };
            formattedData.push(formattedDatum);
          });
          const csv = Papa.unparse(formattedData);
          let blob = new Blob([csv], { type: "text/csv;charset=utf-8" });

          let title =
            session.name +
            "_Tag Log_" +
            moment(session.appointed_timestart).toISOString() +
            ".csv";
          saveAs(blob, title);
          return "File downloading...";
        } else {
          dispatch("errorHandler", response);
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },
  downloadTranscriptAsCSV({ dispatch }, data) {
    // Make this into global value to access from multiple pages.
    // Then we would only need to update this dictionary in one place
    let {session, language, type } = data;
    return Vue.sessionApi
      .get("/s/" + session.uuid + "/transcript-tag-chat")
      .then(
        response => {
          let { data, error } = response.data;
          if (response.status === 200) {
            const formattedData = [];
            data.forEach((datum) => {
              const formattedDatum = {
                timestamp: datum.timestamp,
                "chat username(s)": datum.messages.map(o => o.value?.user?.displayName || o.value?.user?.username || "").join(", "),
                chat: datum.messages.map(o => o.value?.text || o).join(", "),
                "tag username(s)": datum.tags.map(o => o.value?.user?.data?.displayName || o.value?.user?.firstName || o.value?.user?.username || o.value?.user?.preferred_username || o.value?.user || "HARK Keyword").join(", "),
                "tag(s)": datum.tags.map(o => o.value?.tag?.name || o).join(", "),
                "relative timestamp": datum.timecode,
                username: datum.value.participant.name,
                text: datum.value[type][language],
              };
              formattedData.push(formattedDatum);
            });
            const csv = Papa.unparse(formattedData);
            let blob = new Blob(['\ufeff' + csv], {type: 'text/csv;charset=utf-8'});

            let title =
              session.name +
              "_" +
              type +
              "_" +
              language +
              "_" +
              moment(session.appointed_timestart).toISOString() +
              ".csv";
            saveAs(blob, title);
            return "File downloading...";
          } else {
            dispatch("errorHandler", response);
            throw new Error(error);
          }
        },
        error => {
          dispatch("errorHandler", error);
          throw error;
        }
      );
  },

  // Session Users
  createSessionUser({ commit, dispatch }, data) {
    const url = "/sessions/" + data.id + "/users/" + data.uuid;
    return Vue.api.post(url, data.sessionRole).then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          commit("insertSessionInvitations", data);
          return data;
        } else {
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        return Promise.reject(error);
      }
    );
  },
  deleteSessionUser({ commit, dispatch }, data) {
    const url = "/sessions/" + data.session_id + "/users/" + data.user_id;
    return Vue.api.delete(url).then(
      response => {
        if (response.status === 204) {
          commit("removeSessionUser", data);
          return data;
        } else {
          dispatch("errorHandler", response);
          throw new Error(response);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },
  fetchSessionUsers({ commit, dispatch }, id) {
    return Vue.api.get("/sessions/" + id + "/users").then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          commit("setSessionUsers", data);
          return data;
        } else {
          dispatch("errorHandler", response);
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },
  patchSessionUsers({ commit, dispatch }, data) {
    const url = "/sessions/" + data.session_id + "/users/" + data.user_id;
    return Vue.api.patch(url, data.property).then(
      response => {
        if (response.status !== 204) {
          throw new Error(response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },
  fetchSessionProjects({ commit, dispatch }) {
    return Vue.api.get("/sessions-projects").then(
      response => {
        if (response.status === 200) {
          let { data, error } = response.data;
          if (error) {
            dispatch("errorHandler", response);
          } else {
            commit("setProjects", data);
          }
        } else {
          dispatch("errorHandler", response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },

  //
  // Available Resources
  //
  fetchAvailableClients({ commit, dispatch }, availableClientsData) {
    let { id, params } = availableClientsData;
    return Vue.api
      .get("/sessions/" + id + "/available/clients", { params: params })
      .then(
        response => {
          let { data, error } = response.data;
          if (response.status === 200) {
            commit("setPagination", response);
            commit("setAvailableClients", data);
            return data;
          } else {
            dispatch("errorHandler", response);
            throw new Error(error);
          }
        },
        error => {
          dispatch("errorHandler", error);
          throw error;
        }
      );
  },
  fetchAvailableTags({ commit, dispatch }, availableTagsData) {
    let { id, params } = availableTagsData;
    return Vue.api
      .get("/sessions/" + id + "/available/tags", { params: params })
      .then(
        response => {
          let { data, error } = response.data;
          if (response.status === 200) {
            commit("setPagination", response);
            commit("setAvailableTags", data);
            return data;
          } else {
            dispatch("errorHandler", response);
            throw new Error(error);
          }
        },
        error => {
          dispatch("errorHandler", error);
          throw error;
        }
      );
  },
  fetchAvailableTagGroups({ commit, dispatch }, id) {
    return Vue.api.get("/sessions/" + id + "/available/tag-groups").then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          commit("setAvailableTagGroups", data);
          return data;
        } else {
          dispatch("errorHandler", response);
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },

  // Languages
  fetchLanguages({ commit, dispatch }, type) {
    return Vue.api.get(`/languages/${type}`, { params: { pageSize: 200 }}).then(
      response => {
        if (response.status === 200) {
          let { data, error } = response.data;
          if (error) {
            dispatch("errorHandler", response);
          } else {
            commit(`${type}SetLanguages`, data);
          }
        } else {
          dispatch("errorHandler", response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },

  // Reports
  fetchSessionReport({ commit, dispatch, rootGetters}, id) {
    return Vue.api.get(`/sessions/${id}/report`).then(
      response => {
        if (response.status === 200) {
          // Convert base64 string to blob
          const byteCharacters = atob(response.data);
          const sliceSize = 512;
          const byteArrays = [];
          for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }
          const blob = new Blob(byteArrays, {type: 'application/pdf;charset=utf-8'});

          const title = `HARK-${
              rootGetters.session.name
            }-SessionReport-${moment().format()}.pdf`;
          saveAs(blob, title);
          return "File downloading...";
        } else {
          dispatch("errorHandler", response);
          throw new Error(response);
        }
      },
      error => {
        dispatch("errorHandler", error);
        return Promise.reject(error);
      }
    );
  },

  fetchSessionReportDocX({ commit, dispatch, rootGetters}, id) {
    return Vue.api.get(`/sessions/${id}/report-docx`).then(
      response => {
        if (response.status === 200) {
          // Convert base64 string to blob
          const byteCharacters = atob(response.data);
          const sliceSize = 512;
          const byteArrays = [];
          for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }
          const blob = new Blob(byteArrays, {type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"});
          const title = `HARK-${
              rootGetters.session.name
            }-SessionReport-${moment().format()}.docx`;
          saveAs(blob, title);
          return "File downloading...";
        } else {
          dispatch("errorHandler", response);
          throw new Error(response);
        }
      },
      error => {
        dispatch("errorHandler", error);
        return Promise.reject(error);
      }
    );
  },

  /*fetchSessionWordCloud({ commit, dispatch, rootGetters}, id) {
    return Vue.api.get(`/sessions/${id}/word_cloud`).then(
      response => {
        if (response.status === 200) {
          // Convert base64 string to blob
          const byteCharacters = atob(response.data);
          const sliceSize = 512;
          const byteArrays = [];
          for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }
          const blob = new Blob(byteArrays, {type: 'application/pdf;charset=utf-8'});

          const title = `HARK-${
              rootGetters.session.name
            }-SessionReport-${moment().format()}.pdf`;
          saveAs(blob, title);
          return "File downloading...";
        } else {
          dispatch("errorHandler", response);
          throw new Error(response);
        }
      },
      error => {
        dispatch("errorHandler", error);
        return Promise.reject(error);
      }
    );
  },*/

};

const mutations = {
  insertSession(state, session) {
    state.sessions.push(session);
    state.sessionsCounts.unscheduled = state.sessionsCounts.unscheduled + 1;
  },
  insertSessions(state, sessions) {
    //state.sessions.push(sessions);
    state.sessions = state.sessions.concat(sessions);
  },
  showSessionDetailModal(state, sessionObj = false) {
    state.selectedSession = sessionObj;
  },
  setSession(state, session) {
    state.session = session;
  },
  setSessions(state, sessions) {
    //state.sessions = state.sessions.concat(sessions);
    state.sessions = sessions;
  },
  setSessionsCounts(state, sessionsCounts) {
    state.sessionsCounts = sessionsCounts;
  },
  removeSession(state, session) {
    let idx = state.sessions.findIndex(o => {
      return o.id === session.id;
    });
    if (idx >= 0) {
      state.sessions.splice(idx, 1);
    }
  },
  insertSessionClient(state, sessionClient) {
    let client = store.getters.clients.find(o => {
      return o.id === sessionClient.client_id;
    });
    if (!isEmpty(client)) {
      state.session.clients.push(client);
    }
  },
  removeSessionClient(state, sessionClient) {
    let idx = state.session.clients.findIndex(o => {
      return o.id === sessionClient.client_id;
    });
    if (idx >= 0) {
      state.session.clients.splice(idx, 1);
    }
  },
  insertSessionInvitations(state, invitations) {
    state.sessionInvitations = state.sessionInvitations.concat(invitations);
  },
  insertSessionTag(state, sessionTag) {
    let tag = store.getters.tags.find(o => {
      return o.id === sessionTag.tag_id;
    });
    if (!isEmpty(tag)) {
      state.session.tags.push(tag);
    }
  },
  insertSessionTags(state, sessionTags) {
    sessionTags.forEach(tag => {
      let idx = state.session.tags.findIndex(t => t.id === tag.id);
      if (idx >= 0) {
        state.session.tags.splice(idx, 1, tag);
      } else {
        state.session.tags.push(tag);
      }
    });
  },
  insertSessionTagStubs(state, sessionTags) {
    sessionTags.forEach(sessionTag => {
      state.session.tags.push({
        id: sessionTag.tag_id,
        name: " ",
        description: "",
        color: "",
        icon: "",
        type: "loading",
        group: "",
        upload_id: null
      });
    });
  },
  removeSessionTag(state, sessionTag) {
    let idx = state.session.tags.findIndex(o => {
      return o.id === sessionTag.tag_id;
    });
    if (idx >= 0) {
      state.session.tags.splice(idx, 1);
    }
  },
  insertSessionUpload(state, sessionUpload) {
    state.session.uploads.push(sessionUpload);
  },
  removeSessionUpload(state, sessionUpload) {
    let idx = state.session.uploads.findIndex(o => {
      return o.id === sessionUpload.upload_id;
    });
    if (idx >= 0) {
      state.session.uploads.splice(idx, 1);
    }
  },
  setSessionClips(state, sessionClips) {
    state.sessionClips = sessionClips;
  },
  setSessionInvitations(state, sessionInvitations) {
    state.sessionInvitations = sessionInvitations;
  },
  setSessionTags(state, sessionTags) {
    state.session.tags = sessionTags;
  },
  removeSessionUser(state, sessionUser) {
    let idx = state.sessionUsers.findIndex(o => {
      return o.id === sessionUser.user_id;
    });
    if (idx >= 0) {
      state.sessionUsers.splice(idx, 1);
    }
  },
  setSessionUsers(state, sessionUsers) {
    state.sessionUsers = sessionUsers;
    const owner = sessionUsers.find(o => o.id === state.session.owner);
    if (!has(state.session.metadata, "userColors")) {
      state.session.metadata.userColors = {};
      // Add owner color
      state.session.metadata.userColors[owner.email] =
        state.palettes.material[0];
    }
  },
  setSessionVideos(state, sessionVideos) {
    state.sessionVideos = sessionVideos;
  },
  setProjects(state, projects) {
    state.projects = projects;
    // Use set data type to ensure no duplicates
    // const projects = new Set();
    // sessions.forEach(session => {
    //   session.metadata.project &&
    //   projects.add(session.metadata.project.trim());
    // });
    // Use ES6 spread operator to convert set to array
    // state.projects = [...projects].sort();
  },
  addSessionInvitee(state, invitee) {
    state.invitees.push(invitee);
  },
  addNewSessionUser(state, user) {
    state.sessionUsersNew.push(user);
  },
  removeSessionInvitee(state, invitee) {
    let idx = state.invitees.findIndex(o => o.email === invitee.email);
    if (idx >= 0) {
      state.invitees.splice(idx, 1);
    }
  },
  removeNewSessionUser(state, user) {
    let idx = state.sessionUsersNew.findIndex(o => o.id === user.id);
    if (idx >= 0) {
      state.sessionUsersNew.splice(idx, 1);
    }
  },
  clearSessionInvitations(state) {
    state.invitees = [];
  },
  clearNewSessionUsers(state) {
    state.sessionUsersNew = [];
  },

  setAvailable(state, available) {
    state.available = available;
  },
  setAvailableClients(state, clients) {
    state.available.clients = clients;
  },
  setAvailableTags(state, tags) {
    state.available.tags = tags;
  },
  setAvailableTagGroups(state, tagGroups) {
    state.available.tagGroups = tagGroups;
  },
  sourceSetLanguages(state, languages) {
    state.sourceLanguages = languages;
  },
  targetSetLanguages(state, languages) {
    state.targetLanguages = languages;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
