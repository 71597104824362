<template>
  <div id="app">
    <!-- If User Logged In -->
    <SidebarNav v-if="user" />
    <route-loader :isVisible="routeLoader.show" :text="routeLoader.text"/>
    <transition
      enter-active-class="animated slideInDown faster"
      leave-active-class="animated slideOutUp faster"
      mode="out-in"
    >
      <app-header v-if="user && $route.meta.showHeader" class="app-header" />
    </transition>

    <div class="app-inner-view">
      <transition
        enter-active-class="animated slideInLeft faster"
        leave-active-class="animated slideOutRight faster"
        mode="out-in"
      >
        <component :is="layout">
          <router-view />
        </component>
      </transition>
    </div>

    <!-- 
    <div
      style="color:#fff; text-shadow: 1px 1px #111; padding: 0.25em; position:fixed; bottom:0; left:0; z-index:999"
    >
      v{{ version }}
    </div>
    -->

    <div class="notifications">
      <transition-group
        name="notifications"
        enter-active-class="animated slideInRight"
        leave-active-class="animated slideOutRight"
      >
        <div
          v-for="notification in notifications"
          :key="notification.id"
          class="notification"
          :class="[notification.type]"
        >
          <button
            @click="deleteNotification(notification.id)"
            class="delete"
          ></button>
          {{ notification.message }}
          <template v-if="notification.action">
            <br />
            <router-link
              v-if="notification.action.type === 'link'"
              :to="notification.action.url"
              class="button is-fullwidth is-light is-outlined"
              style="justify-content:space-between;"
            >
              <span>{{ notification.action.message }}</span>
              <span class="icon">
                <i class="fas fa-arrow-right" />
              </span>
            </router-link>
            <div v-else-if="notification.action.type === 'component'">
              <component
                :is="notification.action.component"
                :props="notification.action.props"
              ></component>
            </div>
          </template>
        </div>
      </transition-group>
    </div>

    <confirm-modal :class="{ 'is-active': confirmModal.action !== '' }" />

    <!--  tour -->
    <!-- <v-tour name="myTour" :steps="stepsPlusPage" :callbacks="myTourCallbacks">
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-show="tour.currentStep === index"
            v-for="(step, index) of tour.steps"
            :key="index"
            :step="step"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
          </v-step>
        </transition>
      </template>
    </v-tour> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { isEmpty, has } from "lodash/core";

import ConfirmModal from "@/components/ConfirmModal";
// import Navbar from "@/components/Navbar";
import SidebarNav from "@/views/shared/sidebar-nav/SideBarNav.vue";
import AppHeader from "@/views/shared/header/AppHeader.vue";
import RouteLoader from "@/views/shared/loader/RouteLoader.vue";
import Default from "@/layouts/Default";
import FakeModal from "@/layouts/FakeModal";
import eventSources from "@/eventsource";
import Vue from "vue";

// Buefy Components
import { 
  Input,
  Field,
  Tabs, 
  Tag, 
  Pagination, 
  Dropdown, 
  Button,
  Table,
  Collapse,
  ConfigProgrammatic 
} from "buefy";
import "buefy/dist/buefy.css";
Vue.use(Pagination);
Vue.use(Dropdown);
Vue.use(Tabs);
Vue.use(Tag);
Vue.use(Input);
Vue.use(Field);
Vue.use(Button);
Vue.use(Table);
Vue.use(Collapse);
ConfigProgrammatic.setOptions({
  defaultIconPack: "fas"
});

export default {
  name: "home",
  components: {
    ConfirmModal,
    SidebarNav,
    AppHeader,
    RouteLoader
  },
  data() {
    return {
      isMounted: false,
      tourAttempts: 3,
      tourStarted: false,
      TOUR_ACTIVE_CLASS: "tour-active",
      BODY_TOUR: "in-tour",
      steps: [
        // Title is used for pagination
        {
          target: ".v-step-1",
          content:
            '<strong class="is-size-4 has-text-white">Welcome to HARK!</strong><hr> This short tour will help you learn a little more about this site. <br><br> Click <strong class="has-text-white">next</strong> to continue',
          _link: "/dashboard",
          active: false,
          header: { title: "" }
        },
        {
          target: ".v-step-2",
          content:
            'Viewing <strong class="has-text-white is-size-5">Dashboard</strong> is a quick way to see <strong class="has-text-white">active</strong> sessions, <strong class="has-text-white">scheduled</strong> sessions and <strong class="has-text-white">completed</strong> sessions. <br><br> ',
          _link: "/dashboard",
          active: false,
          header: { title: "" }
        },
        {
          target: ".v-step-3",
          content:
            'Using these tabs will help you navigate within <strong class="has-text-white">Dashboard</strong>.',
          _link: "/dashboard",
          active: false,
          header: { title: "" }
        },
        {
          target: ".v-step-3-1",
          content:
            'Here you will be able to see a list of sessions created according to the tab that you are currently viewing. If no session cards are present be sure to create a new session first.<br><br> If you would like learn more on how to get started with sessions please follow this link: <a href="https://docs.harklab.com/guides/getting-started" target="_blank"> docs.harklab.com </a>',
          _link: "/dashboard",
          active: false,
          header: { title: "" }
        },
        {
          target: ".v-step-4",
          content:
            'The <strong class="has-text-white is-size-5">Sessions</strong> tab is where you will find a list of all the sessions you have created in the past. Here you will be able to edit or remove them.',
          _link: "/sessions",
          active: false,
          header: { title: "" }
        },
        {
          target: ".v-step-5",
          content:
            'The <strong class="has-text-white is-size-5">Clients</strong> tab is where you will find a list of all the clients you have added in the past. Here you will be able to edit or remove them.',
          _link: "/clients",
          active: false,
          header: { title: "" }
        },
        {
          target: ".v-step-6",
          content:
            'In the <strong class="has-text-white is-size-5">Tags</strong> tab you will find a list of tags you have created in the past. You will also be able to edit or remove them.',
          _link: "/tags",
          active: false,
          header: { title: "" }
        },
        // {
        //   target: '.v-step-7',
        //   content: 'In the <strong class="has-text-white is-size-5">Surveys</strong> tab you will find a list of surveys you have created in the past. You will also be able to edit or remove them.',
        //   _link: '/surveys',
        //   active: false,
        //   header: {title: ''}
        // },
        // {
        //   target: '.v-step-8',
        //   content: 'Clicking <strong class="has-text-white is-size-5">+ New</strong> button will display a menu with options to create new <strong class="has-text-white">sessions</strong>, <strong class="has-text-white">tags</strong>, <strong class="has-text-white">clients</strong>, <strong class="has-text-white">organizations</strong>, and <strong class="has-text-white">surveys</strong>.',
        //   _link: '/dashboard',
        //   active: false,
        //   header: {title: ''}
        // },
        {
          target: ".v-step-9",
          content:
            'Here you can edit your personal <strong class="has-text-white">profile</strong> and <strong class="has-text-white">account</strong>, report any <strong class="has-text-white">issues</strong> or restart this tour.',
          _link: "/dashboard",
          active: false,
          header: { title: "" }
        }
      ],
      myTourCallbacks: {
        onStart: this.onStartTour,
        onNextStep: this.onNextStep,
        onPreviousStep: this.onPreviousStep,
        onStop: this.onStop,
        onSkip: this.onSkip
      },
      refreshInterval: null
    };
  },
  computed: {
    ...mapGetters([
      "accessTimeout",
      "cdn",
      "confirmModal",
      "notifications",
      "user",
      "version",
      "routeLoader"
    ]),
    stepsPlusPage() {
      const newSteps = this.steps;
      for (let i = 0; i < newSteps.length; i++) {
        var page = i + 1;
        const lastPage = newSteps.length;
        newSteps[i].header.title = "Step " + page + " of " + lastPage;
      }
      return newSteps;
    },
    layout() {
      let l = this.$route.meta.layout;
      if (!isEmpty(l) && l === "fake-modal") {
        return FakeModal;
      } else {
        return Default;
      }
    }
  },
  watch: {
    $route(to, _from) {
      //eslint-disable-line
      document.title = to.meta.title || "HARK";

      // Sematext Experience Monitoring
      // eslint-disable-next-line
      this.$strum("routeChange", document.location.href);
      // console.log(to, from);
      this.showNavBar =
        to.name !== "dashboard" &&
        to.name !== "projects" &&
        to.name !== "account" &&
        to.name !== "login" &&
        to.name !== "clients" &&
        to.name !== "session-view";
    }
  },
  mounted() {
    this.isMounted = true;
    setInterval(() => {
      if (this.user) {
        // Connect to SSE channel
        eventSources.configureEventSources();

        // if (this.tourAttempts > 0) {
        //   this.tourAttempts -= 1;
        let tourSeen = has(this.user.data, "tourSeen")
          ? this.user.data.tourSeen === false
          : false;
        if (this.user && this.tourStarted === false && tourSeen) {
          this.tourStarted = true;
          this.$tours["myTour"].start();
        }
      }
    }, 1000);
    this.refreshInterval = setInterval(this.reauthenticate, this.accessTimeout);
  },
  methods: {
    ...mapActions([
      "deleteNotification",
      "patchUser",
      "refreshSilent"
    ]),
    reauthenticate() {
      if (!isEmpty(this.user)) {
        this.refreshSilent();
      }
    },
    onStartTour() {
      const curStepEl = document.querySelector(this.steps[0].target); //eslint-disable-line

      //Add the in-tour class to body
      // document.body.classList.add(this.BODY_TOUR);
      //Add the active class for the first step
      this.steps[0].active = true;
      this.patch();
    },
    onNextStep(step) {
      const curStepEl = document.querySelector(this.steps[step].target); //eslint-disable-line
      const nextStepEl = document.querySelector(this.steps[step + 1].target); //eslint-disable-line

      //Add active class to next step and remove from current.
      // document.body.classList.add(this.BODY_TOUR);
      this.steps[step].active = false;
      this.steps[step + 1].active = true;
      if (this.steps[step + 1]._link != this.$route.path) {
        this.$router.push(this.steps[step + 1]._link);
      }
    },
    onPreviousStep(step) {
      const curStepEl = document.querySelector(this.steps[step].target); //eslint-disable-line
      const prevStepEl = document.querySelector(this.steps[step - 1].target); //eslint-disable-line

      //Add active step to previous and remove from current.
      // document.body.classList.add(this.BODY_TOUR);

      this.steps[step].active = false;
      this.steps[step - 1].active = true;
      if (this.steps[step - 1]._link != this.$route.path) {
        this.$router.push(this.steps[step - 1]._link);
      }
    },
    onStop() {
      //We finished the tour, so remove in-tour from body and remove active class.
      // document.body.classList.remove(this.BODY_TOUR);
      this.steps.forEach(step => {
        step.active = false;
      });
      this.$router.push("/dashboard");
    },
    patch() {
      let patches = { data: this.user.data };
      patches.data.tourSeen = true;
      this.patchUser(patches);
    }
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
@import "~@/assets/fonts/fonts";
$primary: $hark-blue;
$link: $hark-blue;
$info: $msl-dark-blue;
@import "~bulma";

html,
body {
  background: $background;
  color: $text-color;
  scroll-behavior: smooth;
}

// body.in-tour {
// 		pointer-events: none;
// 	}

// Set index on step to show it above.
.v-step {
  z-index: 9999;
}

// sets the tour-active element to have a shadow that covers rest of the page.
// .tour-active {
// 	position: relative;
// 	z-index: 999;
// }

#app {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  p,
  .label {
    margin-bottom: 0;
  }

  .v-step {
    z-index: 99999;
  }
}

.container {
  margin-top: 1em;
}

.ellipsis-text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.notifications {
  bottom: 0;
  font-size: 1.2em;
  min-width: 30%;
  max-width: 60%;
  padding: 0.5em;
  position: fixed;
  right: 0;
  z-index: 999;

  .notification {
    margin-bottom: 0.5em;
  }
  .notification:last-child {
    margin-bottom: 0;
  }
}

.table.is-rounded {
  border-collapse: collapse;
  border-radius: 4px;
  overflow: hidden;
}

.vue-dropzone {
  border: 2px dashed #e5e5e5;
  border-radius: 6px;
}

a {
  transition: 0.7s;
}

a:hover {
  transition: 0.7s;
}

.button {
  transition: 0.7s;
}

.button:hover {
  transition: 0.7s;
}

.label {
  white-space: nowrap;
}

label.required:after {
  content: "*";
  color: $hark-red;
}

.v-sidebar-menu {
  z-index: 2;
}

.button-row {
  position: relative;
  width: 100%;

  .dashed-line {
    border-bottom: 2px dashed #ddd;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
  }
}
.fa-question-circle {
  color: $hark-blue;
}

.table {
  // border-collapse: separate;
  border-collapse: collapse !important;
  border-spacing: 0.5em;
  // min-width: 720px;
  border: 1px solid #dbdbdb;

  tbody {
    border: none;
  }

  th {
    border-bottom: 1px solid #dbdbdb;
    text-align: left;
    color: #404e5a;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    background: #f0f2f3 0% 0% no-repeat padding-box;
    padding: 0.6em 1em;
    border-width: 0px;
    &:first-child {
      width: 30%;
    }
  }
  td {
    color: #515355;
    font-size: 14px;
    font-weight: bold;
    padding: 1em;
    border-width: 0px;
    border-bottom-width: 1px;
  }
  th:not(:last-child) {
    min-width: 9rem;
  }
  tr {
    &:last-child {
      td {
        border-bottom-width: 1px;
      }
    }
  }
}

// Vue-Color Chrome component used in TagEditor.vue
.vc-chrome .vc-chrome-saturation-wrap {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

// Cool Select component styles
.IZ-select__input {
  border: none !important;
  border-radius: 0px !important;
}
.IZ-select__input.IZ-select__input--focused {
  border: none;
  box-shadow: none !important;
}
.IZ-select__input input {
  background-color: #f0f2f3 !important;
  border: none !important;
  border-color: transparent;
  border-radius: 0px !important;
  // color: #363636;
  color: #515355;
  font-size: 14px;
  letter-spacing: 0.09px;
  padding-bottom: calc(0.375em - 1px) !important;
  padding-left: calc(0.625em - 1px) !important;
  padding-top: calc(0.375em - 1px) !important;
  transition: border 0.5s ease;
}

// mobile styles
@media only screen and (max-width: 1023px) {
  .app-header {
    background-color: #1f2532;
    padding: 1em;
    display: flex;
    flex-direction: column;

    .app-header-box {
      position: unset;
      height: 50px;
      display: flex;
      align-items: center;

      .columns {
        .column {
          margin-top: 0px;
        }
      }
    }

    .page-title {
      display: flex;
      flex-direction: column;
    }

    #title-nav {
      top: unset;
    }
  }
  // .pickers-container { height: 100%; } /* optional: move accept button at bottom of screen */
  .time-picker {
    max-height: 200px;
  }

  .table-mobile-sort {
    display: none;
  }
}
@import "./styles/app.scss";
@import "./styles/media.scss";
</style>
