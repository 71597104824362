import Vue from "vue";

const state = {
  role: null,
  roles: []
};

const getters = {
  role: state => state.role,
  roles: state => state.roles
};

const actions = {
  createRole({ commit, dispatch }, role) {
    return Vue.api.post("/roles", role).then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          commit("insertRole", data);
          return data;
        } else {
          dispatch("errorHandler", response);
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },
  deleteRole({ commit, dispatch }, role) {
    return Vue.api.delete("/roles/" + role.id).then(
      response => {
        if (response.status === 204) {
          commit("removeRole", role);
          dispatch("notifySuccess", "Role deleted successfully.");
        } else {
          dispatch("errorHandler", response);
        }
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  fetchRole({ commit, dispatch }, id) {
    return Vue.api.get("/roles/" + id).then(
      response => {
        if (response.status === 200) {
          let { data, error } = response.data;
          if (error) {
            dispatch("errorHandler", response);
          } else {
            commit("setRole", data);
          }
        } else {
          dispatch("errorHandler", response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  fetchRoles({ commit, dispatch }, params) {
    return Vue.api.get("/roles", { params: params }).then(
      response => {
        if (response.status === 200) {
          let { data, error } = response.data;
          if (error) {
            dispatch("errorHandler", response);
          } else {
            commit("setPagination", response);
            commit("setRoles", data);
            return data;
          }
        } else {
          dispatch("errorHandler", response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  patchRole({ dispatch }, role) {
    return Vue.api.patch("/roles/" + role.id, role.property).then(
      response => {
        if (response.status !== 204) {
          dispatch("errorHandler", response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  updateRole({ commit, dispatch }, role) {
    return Vue.api.put("/roles/" + role.id, role).then(
      response => {
        if (response.status === 200) {
          let { data, error } = response.data;
          if (error) {
            dispatch("errorHandler", response);
          } else {
            commit("setRole", data);
          }
        } else {
          dispatch("errorHandler", response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  }
};

const mutations = {
  insertRole(state, role) {
    state.roles.push(role);
  },
  removeRole(state, role) {
    let idx = state.roles.findIndex(o => {
      return o.id === role.id;
    });
    if (idx >= 0) {
      state.roles.splice(idx, 1);
    }
  },
  setRole(state, role) {
    state.role = role;
  },
  setRoles(state, roles) {
    state.roles = roles;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
