<template>
  <div>
    <div class="app-header-box">
      <div class="is-mobile columns">
        <button
          class="menu-toggle d-md-none"
          type="button"
          @click="triggerMenu"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <h1 class="page-title column">
          {{ headerTitle }}
          <b-tag
            v-if="isTagActive"
            attached
            closable
            aria-close-label="Close tag"
            @close="clearFilter"
            class="tag-text"
            :key="tagText"
            >{{ tagText }}</b-tag
          >
        </h1>
        <div id="title-nav" class="column has-text-right">
          <button
            to="#"
            @click="purchase"
            class="button is-link is-small btn-style1 mr-5 is-uppercase"
          >
            Buy&nbsp;Sessions
          </button>
          <div v-if="user" class="dropdown is-hoverable is-right">
            <div class="dropdown-trigger">
              <router-link
                to="/account"
                class="mr-5 header-icon-btns"
                aria-haspopup="true"
                aria-controls="dropdown-menu"
              >
                <user-avatar v-if="user.imageUrl" :size="26" :user="user" />
                <img v-else class="icon" src="@/assets/profile.svg" alt />
              </router-link>
            </div>
            <div class="dropdown-menu" role="menu">
              <div class="dropdown-content">
                <div class="dropdown-item">
                  {{ user.email }}
                </div>
                <hr class="dropdown-divider" />
                <router-link :to="{ name: 'account' }" class="dropdown-item">
                  Profile
                </router-link>
                <hr class="dropdown-divider" />
                <!--
                <a @click="$tours['myTour'].start()" class="dropdown-item">
                  User tour
                </a>
                <hr class="dropdown-divider" />
                -->
                <a @click="logout" class="dropdown-item">
                  Sign Out
                </a>
              </div>
            </div>
          </div>
          <div v-if="user" class="dropdown is-hoverable is-right">
            <div class="dropdown-trigger">
              <router-link
                :to="{ name: 'submit-feedback' }"
                class="header-icon-btns"
                aria-haspopup="true"
                aria-controls="dropdown-menu"
              >
                <img class="icon" src="@/assets/help.svg" alt />
              </router-link>
            </div>
            <div class="dropdown-menu" role="menu">
              <div class="dropdown-content">
                <router-link :to="{ name: 'submit-feedback' }" class="dropdown-item">
                  User Feedback
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { has } from "lodash/core";
import Vue from "vue";

import UserAvatar from "@/components/UserAvatar";

export default {
  name: "app-header",
  components: {
    UserAvatar
  },
  props: {},
  data() {
    return {
      isTagActive: false,
      isMenuActive: false,
      filterTag: "",
      viewportWidth: 0,
      countsParams: {
        projectFilter: "",
        clientFilter: "",
      }
    };
  },
  computed: {
    ...mapGetters(["sentry", "user", "sessions", "projects", "clients"]),
    tagText() {
      let txt = "";
      if (
        undefined !== this.$route.query["projectFilter"] &&
        this.$route.query["projectFilter"] > 0
      ) {
        const project = this.projects.find(project => project.id == this.$route.query["projectFilter"]);
        if (project?.name) {
          txt = "Project: " + project.name;
        }
      }
      if (
        undefined !== this.$route.query["clientFilter"] &&
        parseInt(this.$route.query["clientFilter"]) > 0
      ) {
        txt = "Client: " + this.filterTag;
      }
      return txt;
    },
    headerTitle() {
      if (process.env.NODE_ENV === "development") {
        if (this.$route.meta && this.$route.meta.displayName) {
          return this.$route.meta.displayName;
        } else {
          return `${this.$route.name} - Please add meta.displayName to your route!`;
        }
      } else {
        if (this.$route.meta) {
          return this.$route.meta.displayName || "";
        } else {
          return "";
        }
      }
    }
  },
  watch: {
    // Set isTagActive to false if not on dashboard
    "$route.name": {
      handler(newParams) {
        if (newParams !== "dashboard"){
          this.isTagActive = false;
        } 
      }
    },
    "$route.query": {
      handler(_newParams) {
        Object.keys(_newParams).forEach((key, _index) => {
          if (key === "projectFilter" && !!this.$route.query[key]) {
            this.filterTag = this.$route.query["projectFilter"];
            this.countsParams.projectFilter = this.$route.query["projectFilter"];
            this.fetchSessionsCounts(this.countsParams);
            this.isTagActive = true;
          } else if (key === "clientFilter" && !!this.$route.query[key] ) {
            const client = this.clients.find(client => client.id == this.$route.query["clientFilter"]);
            this.filterTag = client.name;
            this.countsParams.clientFilter = this.$route.query["clientFilter"];
            this.fetchSessionsCounts(this.countsParams);
            this.isTagActive = true;
          }
        });
      this._computedWatchers.tagText.run();
      this.$forceUpdate();
      },
      deep: true
    }
  },
  mounted() {
    // this.viewportWidth = window.innerWidth;
    // window.addEventListener('resize', () => this.viewportWidth = window.innerWidth);
    // Check for query parameters
    Object.keys(this.$route.query).forEach((key, _index) => {
      if (has(this.$route.query, key)) {
        if (key === "projectFilter") {
          this.filterTag = this.$route.query["projectFilter"];
          if (this.filterTag) {
            this.isTagActive = true;
          }
        }
        if (key === "clientFilter") {
          const client = this.clients.find(client => client.id == this.$route.query["clientFilter"]);
          this.filterTag = client?.name;
          if (this.filterTag) {
            this.isTagActive = true;
          }
        }
      }
    });
    if (!this.projects.length) {
      this.fetchSessionProjects().then(()=> this.$forceUpdate());
    };
    if (!this.clients.length) {
      this.fetchClients().then(()=>{ 
        if (this.$route.query["clientFilter"]) {
          const client = this.clients.find(client => client.id == this.$route.query["clientFilter"]);
          this.filterTag = client?.name;
          if (this.filterTag) {
            this.isTagActive = true;
          }
        }
        this.$forceUpdate()
      });
    };
  },
  methods: {
    ...mapActions([
      "purchaseSessions",
      "logout",
      "fetchSessionProjects",
      "fetchClients",
      "fetchSessionsCounts"
    ]),
    showSentryDialog() {
      this.sentry.captureException(new Error("User Feedback Submitted"));
      this.sentry.showReportDialog({
        user: {
          email: this.user.email,
          name: this.user.displayName
        },
        title: "Report An Issue",
        labelSubmit: "Submit Feedback"
      });
    },
    clearFilter() {
      this.$router.push({ query: { projectFilter: "", clientFilter: "" } });
      this.fetchSessionsCounts();
      this.isTagActive = false;
    },
    triggerMenu() {
      if (this.isMenuActive == false) {
        this.isMenuActive = true;
        this.$root.$emit("openMobileMenu", this.isMenuActive);
      } else {
        this.isMenuActive = false;
        this.$root.$emit("closeMobileMenu", this.isMenuActive);
      }
    },
    purchase() {
      if (this.$route.path === "/dashboard/unscheduled") {
        this.purchaseSessions(true);
      } else {
        this.purchaseSessions(false);
      }
    }
  }
};
</script>

<style lang="scss">
.app-header {
  background-color: #f5f8f9;
  padding-left: 264px;
  padding-right: 44px;
  text-align: left;
  min-height: 80px;
  // align-items: center;
  // display: grid;

  .app-header-box {
    position: relative;
    .columns {
      .column {
        margin-top: 10px;
      }
    }
  }

  .tags {
    display: inline-block;
    background: rgba(126, 126, 126, 0.2);
    padding: 6px 9px;
    position: relative;
    padding-right: 42px;
    vertical-align: top;
    border-radius: 3px;
    > span {
      margin: 0;
      vertical-align: top;
      color: #515355;
      font-size: 14px;
      font-weight: 500;
      float: left;
    }
    > a.is-delete {
      width: 0.7em;
      height: 0.7em;
      position: absolute;
      top: 8px;
      right: 12px;
      background: none !important;
      margin: 0;
    }
    > a.is-delete:hover {
      color: #000000;
    }
    .tag:not(body).is-delete::after {
      height: 100%;
    }
    .tag:not(body).is-delete::before {
      width: 100%;
    }
  }
}

.tag-text {
  span {
    white-space: nowrap;
    width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

#title-nav {
  position: absolute;
  top: 3px;
  right: 0;
}

.button {
  border-radius: 5px;
}

.header-icon-btns {
  background: none;
  border: none;
  cursor: pointer;
}

.header-icon-btns {
  background: none;
  border: none;
  cursor: pointer;
}

.dropdown-menu .dropdown-content {
  background: $midnight;
  border: 1px solid #dbdbdb;
  border-radius: 0;
  box-shadow: 2px 2px 3px rgba(10, 10, 10, 0.1);
  color: white;

  .dropdown-item {
    color: white;
  }
  a.dropdown-item:hover {
    background-color: $hark-blue;
  }
}
</style>
