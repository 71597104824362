import Vue from "vue";
import axios from "axios";
import { saveAs } from "file-saver";

const state = {
  curDownload: 0,
  downloads: {}
};

const getters = {
  curDownload: state => state.curDownload,
  downloads: state => state.downloads
};

const actions = {
  /**
   * Get a signed URL to allow client to directly download video
   * @param {*} videoData - Contains session and video information
   */
  downloadVideoS3({ dispatch }, videoData) {
    let { video } = videoData;
    return Vue.api
      .get("/signed-url/download", { params: { filename: video } })
      .then(
        response => {
          let { data, error } = response.data;
          if (response.status === 200) {
            videoData.url = data;
            return dispatch("s3GetObject", videoData);
          } else {
            dispatch("errorHandler", response);
            throw new Error(error);
          }
        },
        error => {
          dispatch("errorHandler", error);
          throw error;
        }
      );
  },

  /**
   *
   * @param {*} videoData - Contains video information and signed URL from S3
   */
  s3GetObject({ commit, dispatch }, videoData) {
    let { video, url } = videoData;
    let options = {
      responseType: "blob",
      onDownloadProgress: progressEvent => {
        let progress = Math.floor(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        // console.log("Download progress", progress);
        // dispatch("updateDownloadProgress", { file: video, progress: progress });
        commit("setDownloadProgress", { file: video, progress: progress });
        // Vue.$forceUpdate();
        if (progress >= 100) {
          commit("setDownloadProgress", { file: video, progress: -1 });
        }
      }
    };
    return axios.get(url, options).then(
      response => {
        if (response.status === 200) {
          let filename = video.split("/").pop();
          let blob = new Blob([response.data], { type: "video/mp4" });
          saveAs(blob, filename);
          return "Video downloaded: " + filename;
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },

  /**
   * Fetch a list of session videos available from AWS S3
   * @param {*} session
   */
  fetchS3SessionVideos({ commit, dispatch }, session) {
    return Vue.api.get("/sessions/" + session.uuid + "/videos").then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          commit("setSessionVideos", data.Contents);
          return data.Contents;
        } else {
          dispatch("errorHandler", response);
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },

  updateDownloadProgress({ commit }, downloadData) {
    commit("setDownloadProgress", downloadData);
  },

  /**
   * Fetch a list of session clips available from AWS S3
   * @param {*} session
   */
  fetchS3SessionClips({ commit, dispatch }, session) {
    return Vue.api.get("/sessions/" + session.uuid + "/clips").then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          // commit("setSessionClips", data.Items);
          // return data.Items;
          commit("setSessionClips", data.Contents);
          return data.Contents;
        } else {
          dispatch("errorHandler", response);
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  }
};

const mutations = {
  clearDownloads(state) {
    state.curDownload = 0;
    state.downloads = {};
  },
  setDownloadProgress(state, { file, progress }) {
    state.curDownload = progress;
    state.downloads[file] = progress;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
