export default store => {
  const ability = store.getters.ability;

  ability.update(store.state.rules);

  return store.subscribe(mutation => {
    switch (mutation.type) {
      case "createCASL":
        ability.update(mutation.payload.rules);
        break;
      case "destroyCASL":
        ability.update([{ actions: "read", subject: "all" }]);
        break;
    }
  });
};
