<template>
  <div id="main-content">
    <div>
      <router-link :to="{ path: backButtonPath }" class="close-btn">
        <!--a class="close-btn" @click="$router.go(-1)"-->
        <img src="@/assets/close-light.svg" />
        <!--/a-->
      </router-link>
    </div>
    <!--div v-if="!slotContentLoaded"><i class="fas fa-spinner fa-spin fa-5x" style="color: white;"></i></div-->
    <transition
      enter-active-class="animated fadeIn faster"
      leave-active-class="animated fadeOut faster"
      mode="out-in"
    >
      <slot />
    </transition>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "fake-modal",
  data() {
    return {
      slotContentLoaded: false,
      slotInterval: null
    };
  },
  computed: {
    ...mapGetters([]),
    backButtonPath() {
      let previous =
        this.$routerHistory.previous().path || "/dashboard/scheduled";
      if (previous.match(/\/join\//)) return "/dashboard/scheduled";
      return previous;
    }
  },
  mounted() {
    // TODO: Try and set up loading spinner
    /*this.slotInterval = setInterval(()=> {
      if (!this.slotContentLoaded) {
        if (this.$slots.default && !!this.$slots.default[0].text && !!this.$slots.default[0].text.length) {
          this.slotContentLoaded = true;
        }
      } else {
        clearInterval(this.slotInterval);
      }
    }, 100);*/
  },
  methods: {
    ...mapActions([])
  }
};
</script>
<style lang="scss" scoped>
#main-content {
  background: #404e5a;
  padding: 40px;
  padding-bottom: 70px;
  height: 100%;
  padding-left: 264px;
  padding-right: 40px;
  transition: all 1s;
  min-height: 100vh;

  .close-btn {
    background: transparent;
    border: transparent;
    color: white;
    font-size: 30px;
    font-weight: 300;
    float: right;
    margin-left: 0.5em;
    right: 100px;
    top: 52px;
    z-index: 100;
  }
}

// mobile styles
@media only screen and (max-width: 1023px) {
  #main-content {
    padding: 15px 15px 15px 15px;
  }
}
</style>
