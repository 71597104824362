import Vue from "vue";
import router from "@/router";
import { Ability } from "@casl/ability";
import eventSources from "@/eventsource";

const TYPE_KEY = Symbol("resourceType");

const state = {
  credentials: { username: "", password: "" },
  maxRefreshAttempts: 3,
  refreshAttempts: 3,
  tempEmail: "",
  transactions: [],
  user: null
};

const getters = {
  ability() {
    return new Ability([], {
      subjectName(subject) {
        return !subject || typeof subject === "string"
          ? subject
          : subject[TYPE_KEY];
      }
    });
  },
  credentials: state => state.credentials,
  tempEmail: state => state.tempEmail,
  transactions: state => state.transactions,
  user: state => state.user
};

const actions = {
  //
  // JWT
  fetchClaims({ commit, dispatch }) {
    return Vue.api.get("/claims").then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          commit("setUser", data);
          commit("resetRefreshAttempts");
          return data;
        } else {
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        return Promise.reject(error);
      }
    );
  },
  attemptRefreshToken({ dispatch, rootState }) {
    console.log("Refresh: " + rootState.user.refreshAttempts);
    if (rootState.user.refreshAttempts <= 0) {
      // dispatch("notifyError", "Unauthorized.");
      dispatch("logout");
    } else {
      Vue.api.get("/refresh").then(
        response => {
          let { error } = response.data;
          if (response.status === 200) {
            dispatch("fetchClaims");
          } else {
            throw new Error(error);
          }
        },
        error => {
          dispatch("errorHandler", error);
          return Promise.reject(error);
        }
      );
    }
  },
  refreshSilent(_context) {
    return Vue.api.get("/refresh");
  },

  //
  // Login / Logout
  login({ commit, dispatch }, user) {
    return Vue.api.post("/login", user).then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          dispatch("fetchClaims");
          // Set user claims
          commit("setUser", data);
          return data;
        } else {
          dispatch("errorHandler", response);
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },
  logout({ commit, dispatch }) {
    return Vue.api.get("/logout").then(
      response => {
        let { error } = response.data;
        if (response.status === 200) {
          commit("setUser", null);
          commit("resetRefreshAttempts");

          // Close SSE channel
          eventSources.closeEventSource();

          router.push("/login");
        } else {
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
    // delete Vue.api.defaults.headers.common["Authorization"];
    // delete Vue.sessionApi.defaults.headers.common["Authorization"];
    // commit("setUser", null);
    // commit("resetRefreshAttempts");
    // document.cookie =
    //   "accessToken=;domain=.harklab.com;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT";
    // document.cookie =
    //   "accessToken=;domain=.harkconnect.com;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT";
    // document.cookie =
    //   "refreshToken=;domain=.harklab.com;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT";
    // document.cookie =
    //   "refreshToken=;domain=.harkconnect.com;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT";
    // router.push("/sign-in");
  },

  //
  // Registration Functions
  checkEmail({ dispatch }, email) {
    return Vue.api.get("/emails/" + email + "/available").then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          return data;
        } else {
          dispatch("errorHandler", error);
        }
      },
      error => {
        dispatch("errorHandler", error);
      }
    );
  },
  checkUsername({ dispatch }, username) {
    return Vue.api.get("/usernames/" + username + "/available").then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          return data;
        } else {
          dispatch("errorHandler", error);
        }
      },
      error => {
        dispatch("errorHandler", error);
      }
    );
  },
  register({ dispatch }, user) {
    return Vue.api.post("/register", user).then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          return data;
        } else {
          dispatch("errorHandler", error);
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },

  // Verify user's email address
  verifyEmail({ dispatch }, verificationId) {
    return Vue.api.get("/verify-email/" + verificationId).then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          return data;
        } else {
          dispatch("errorHandler", error);
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },
  // Resend verification email
  resendVerificationEmail({ dispatch }, email) {
    return Vue.api.put("/verify-email", email).then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          return data;
        } else {
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },
  // TODO: Deprecate - no longer used in email verification flow
  // Verify user's email address
  sendVerificationEmail({ dispatch }, email) {
    return Vue.api.post("/verify-email?email=" + email).then(
      response => {
        let { error } = response.data;
        if (response.status === 200) {
          return response;
        } else {
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },

  // Forgot Password
  forgotPassword({ dispatch }, email) {
    return Vue.api.post("/forgot", email).then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          return data;
        } else {
          // dispatch("errorHandler", error);
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        return Promise.reject(error);
      }
    );
  },
  setForgottenPassword({ dispatch }, passwordData) {
    return Vue.api.post("/forgot/change-password", passwordData).then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          return data;
        } else {
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        return Promise.reject(error);
      }
    );
  },

  // Change user's password
  changePassword(_context, password) {
    return Vue.api.post("/change-password", password).then(
      response => {
        // let { data, error } = response.data;
        if (response.status === 200) {
          return response;
        } else {
          //dispatch("errorHandler", error);
          // throw new Error(error);
          return response;
        }
      },
      error => {
        // dispatch("errorHandler", error);
        return error;
      }
    );
  },

  // Manage User
  patchUser({ dispatch }, user) {
    return Vue.api.patch("/me", user).then(
      response => {
        if (response.status !== 204) {
          throw new Error(response.data?.error);
        }
        dispatch("refreshSilent");
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return Promise.reject(error);
      }
    );
  },

  // Paddle
  fetchTransactions({ commit, dispatch }) {
    return Vue.api.get("/paddle/transactions").then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          commit("setTransactions", data);
          return data;
        } else {
          dispatch("errorHandler", error);
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  }
};

const mutations = {
  decRefreshAttempts(state) {
    state.refreshAttempts -= 1;
  },
  resetRefreshAttempts(state) {
    state.refreshAttempts = state.maxRefreshAttempts;
  },
  setTempEmail(state, email) {
    state.tempEmail = email;
  },
  setTransactions(state, transactions) {
    state.transactions = transactions;
  },
  setUser(state, user) {
    state.user = user;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
