<template>
  <div class="modal">
    <div @click="close" class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ confirmModal.title }}</p>
        <button @click="close" class="delete" aria-label="close"></button>
      </header>
      <section class="modal-card-body">
        <div v-html="confirmModal.message"></div>
      </section>
      <footer class="modal-card-foot">
        <button @click="close" class="button">Cancel</button>
        <button @click="confirm" class="button" :class="inferColor">
          {{ confirmModal.buttonLabel }}
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { some, isEmpty } from "lodash/core";

export default {
  name: "confirm-modal",
  computed: {
    ...mapGetters(["confirmModal"]),
    inferColor() {
      let lbl = this.confirmModal.buttonLabel.toLowerCase();
      let danger = ["delete", "remove"];
      let success = ["add", "create", "new"];
      if (some(danger, el => lbl.includes(el))) {
        return "is-danger";
      } else if (some(success, el => lbl.includes(el))) {
        return "is-success";
      } else {
        return "";
      }
    }
  },
  mounted() {},
  methods: {
    ...mapActions([]),
    close() {
      this.$emit("close");
      this.$store.commit("clearConfirmModal");
    },
    confirm() {
      let { action, args } = this.confirmModal;
      if (!isEmpty(action)) {
        if (isEmpty(args)) {
          this.$store.dispatch(action).then(() => {
            this.close();
          });
        } else {
          this.$store.dispatch(action, args).then(() => {
            this.close();
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-card-body {
  font-size: 1.25em;
  text-align: left;

  small {
    font-size: 12px;
  }

  .box {
    margin: 0 0 0.5em 0.5em;
    max-width: 190px;
  }

  .box.is-active {
    box-shadow: 0 2px 3px $msl-light-blue, 0 0 0 1px $msl-light-blue;
  }
}

.modal-card-foot {
  justify-content: space-between;
}
</style>
