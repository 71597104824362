import Vue from "vue";

// This was a strange hack to make the survey reset the state correctly. Seems like this was being overwritten before somehow.
const baseForm = JSON.stringify({
  id: "",
  name: "",
  sections: [
    {
      id: 1,
      name: "",
      description: "",
      visible: true,
      dropdown: false,
      questions: [
        {
          id: 1,
          type: "Short Answer",
          question: "",
          binaryString: "faces",
          required: false,
          visible: true,
          rules: "",
          options: [
            {
              id: 1,
              option: "",
              checked: false
            }
          ],
          scaleData: {
            firstAnchor: 0,
            lastAnchor: 2,
            labels: [
              {
                id: 1,
                label: ""
              },
              {
                id: 2,
                label: ""
              }
            ]
          }
        }
      ]
    }
  ]
});

const state = {
  forms: [],
  form: {
    id: "",
    name: "",
    sections: [
      {
        id: 1,
        name: "",
        description: "",
        visible: true,
        dropdown: false,
        questions: [
          {
            id: 1,
            type: "Short Answer",
            question: "",
            binaryString: "faces",
            required: false,
            visible: true,
            rules: "",
            options: [
              {
                id: 1,
                option: "",
                checked: false
              }
            ],
            scaleData: {
              firstAnchor: 0,
              lastAnchor: 2,
              labels: [
                {
                  id: 1,
                  label: ""
                },
                {
                  id: 2,
                  label: ""
                }
              ]
            }
          }
        ]
      }
    ]
  }
};

const getters = {
  form: state => state.form,
  forms: state => state.forms
};

const actions = {
  saveSurvey({ commit, dispatch }, survey) {
    return Vue.api.post("/surveys", survey).then(
      response => {
        if (response.status === 200) {
          let { data, error } = response.data;
          if (error) {
            dispatch("errorHandler", response);
          } else {
            commit("insertForm", data);
          }
        } else {
          dispatch("errorHandler", response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  newSurvey({ commit }) {
    commit("resetForm");
  },
  fetchSurvey({ commit, dispatch }, id) {
    return Vue.api.get("/surveys/" + id).then(
      response => {
        if (response.status === 200) {
          let { data, error } = response.data;
          if (error) {
            dispatch("errorHandler", response);
          } else {
            commit("setSurvey", data);
          }
        } else {
          dispatch("errorHandler", response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  fetchSurveys({ commit, dispatch }, params) {
    return Vue.api.get("/surveys", { params: params }).then(
      response => {
        if (response.status === 200) {
          let { data, error } = response.data;
          if (error) {
            dispatch("errorHandler", response);
          } else {
            commit("setPagination", response);
            commit("setSurveys", data);
            return data;
          }
        } else {
          dispatch("errorHandler", response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  updateSurvey({ commit, dispatch }, survey) {
    return Vue.api.put("/surveys/" + survey.id, JSON.stringify(survey)).then(
      response => {
        if (response.status === 200) {
          let { data, error } = response.data;
          if (error) {
            dispatch("errorHandler", response);
          } else {
            commit("setSurvey", data);
            dispatch("notifySuccess", "Survey Updated!");
          }
        } else {
          dispatch("errorHandler", response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  removeSurvey({ commit, dispatch }, survey) {
    return Vue.api.delete("/surveys/" + survey.id).then(
      response => {
        if (response.status === 204) {
          commit("deleteForm", survey);
        } else {
          dispatch("errorHandler", response);
        }
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  insertOption({ commit }, data) {
    commit("insertOption", data);
  },
  editOption({ commit }, data) {
    commit("editOption", data);
  },
  removeOption({ commit }, data) {
    commit("deleteOption", data);
  },
  insertQuestion({ commit }, data) {
    commit("insertQuestion", data);
  },
  editQuestion({ commit }, data) {
    commit("editQuestion", data);
  },
  removeQuestion({ commit }, data) {
    commit("deleteQuestion", data);
  },
  insertSection({ commit }, section) {
    commit("insertSection", section);
  },
  editSection({ commit }, data) {
    commit("editSection", data);
  },
  removeSection({ commit }, sectionIdx) {
    commit("deleteSection", sectionIdx);
  },
  updateSections({ commit }, data) {
    commit("updateSections", data);
  },
  updateSectionQuestions({ commit }, data) {
    commit("updateSectionQuestions", data);
  },
  updateQuestionOptions({ commit }, data) {
    commit("updateQuestionOptions", data);
  },
  insertLabel({ commit }, data) {
    commit("insertLabel", data);
  },
  editLabel({ commit }, data) {
    commit("editLabel", data);
  },
  removeLabel({ commit }, data) {
    commit("deleteLabel", data);
  }
};

const mutations = {
  insertForm(state, data) {
    state.forms.push(data);
  },
  resetForm(state) {
    const newBaseForm = JSON.parse(baseForm);
    state.form = newBaseForm;
  },
  deleteForm(state, data) {
    let idx = state.forms.findIndex(o => {
      return o.id === data.id;
    });
    if (idx >= 0) {
      state.forms.splice(idx, 1);
    }
  },
  insertOption(state, data) {
    state.form.sections[data.sectionIndex].questions[
      data.questionIndex
    ].options.push(data.option);
  },
  editOption(state, data) {
    state.form.sections[data.sectionIndex].questions[
      data.questionIndex
    ].options[data.optionIndex] = data.option;
  },
  deleteOption(state, data) {
    state.form.sections[data.sectionIndex].questions[
      data.questionIndex
    ].options.splice(data.optionIndex, 1);
  },
  insertQuestion(state, data) {
    state.form.sections[data.sectionIndex].questions.push(data.question);
  },
  editQuestion(state, data) {
    state.form.sections[data.sectionIndex].questions[data.questionIndex] =
      data.question;
  },
  deleteQuestion(state, data) {
    state.form.sections[data.sectionIndex].questions.splice(
      data.questionIndex,
      1
    );
  },
  insertSection(state, section) {
    state.form.sections.push(section);
  },
  editSection(state, data) {
    state.form.sections[data.sectionIndex] = data.section;
  },
  deleteSection(state, sectionIdx) {
    state.form.sections.splice(sectionIdx, 1);
  },
  updateSections(state, sections) {
    state.form.sections = sections;
  },
  updateSectionQuestions(state, data) {
    state.form.sections[data.sectionIndex].questions = data.questions;
  },
  updateQuestionOptions(state, data) {
    state.form.sections[data.sectionIndex].questions[data.questionIndex] =
      data.options;
  },
  insertLabel(state, data) {
    state.form.sections[data.sectionIndex].questions[
      data.questionIndex
    ].scaleData.labels.push(data.label);
  },
  editLabel(state, data) {
    state.form.sections[data.sectionIndex].questions[
      data.questionIndex
    ].scaleData.labels[data.labelIndex] = data.label;
  },
  deleteLabel(state, data) {
    state.form.sections[data.sectionIndex].questions[
      data.questionIndex
    ].scaleData.labels.splice(data.labelIndex, 1);
  },
  setSurvey(state, data) {
    state.form = data.survey;
    state.form.id = data.id;
  },
  setSurveys(state, data) {
    state.forms = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
