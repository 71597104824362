import Vue from "vue";
import { isEmpty } from "lodash/core";

const state = {
  client: null,
  clients: [],
  clientsProjects: []
};

const getters = {
  client: state => state.client,
  clients: state => state.clients,
  clientsProjects: state => state.clientsProjects,
  archivedClients: (state, getters) => {
    if (isEmpty(getters.sessions)) return [];
    let data = {};
    getters.sessions.forEach(session => {
      session.clients.forEach(client => {
        if (!data[client.id]) {
          data[client.id] = { id: client.id, name: client.name, count: 1 };
        } else {
          data[client.id].count += 1;
        }
      });
    });
    return Object.values(data);
  }
};

const actions = {
  createClient({ commit, dispatch }, client) {
    return Vue.api.post("/clients", client).then(
      response => {
        let { data, error } = response.data;
        if (response.status === 200) {
          commit("insertClient", data);
          return data;
        } else {
          dispatch("errorHandler", response);
          throw new Error(error);
        }
      },
      error => {
        dispatch("errorHandler", error);
        throw error;
      }
    );
  },
  deleteClient({ commit, dispatch }, client) {
    return Vue.api.delete("/clients/" + client.id).then(
      response => {
        if (response.status === 204) {
          commit("removeClient", client);
          dispatch("notifySuccess", "Client deleted successfully.");
        } else {
          dispatch("errorHandler", response);
        }
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  fetchClient({ commit, dispatch }, id) {
    return Vue.api.get("/clients/" + id).then(
      response => {
        if (response.status === 200) {
          let { data, error } = response.data;
          if (error) {
            dispatch("errorHandler", response);
          } else {
            commit("setClient", data);
          }
        } else {
          dispatch("errorHandler", response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  fetchClients({ commit, dispatch }, params) {
    return Vue.api.get("/clients", { params: params }).then(
      response => {
        if (response.status === 200) {
          let { data, error } = response.data;
          if (error) {
            dispatch("errorHandler", response);
          } else {
            commit("setPagination", response);
            commit("setClients", data);
            return data;
          }
        } else {
          dispatch("errorHandler", response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  fetchClientsProjects({ commit, dispatch }) {
    return Vue.api.get("/clients/projects").then(
      response => {
        if (response.status === 200) {
          let { data, error } = response.data;
          if (error) {
            dispatch("errorHandler", response);
          } else {
            commit("setClientsProjects", data);
          }
        } else {
          dispatch("errorHandler", response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  patchClient({ dispatch }, client) {
    return Vue.api.patch("/clients/" + client.id, client.property).then(
      response => {
        if (response.status !== 204) {
          dispatch("errorHandler", response);
        }
        dispatch("notifySuccess", "Client updated successfully.");
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  },
  updateClient({ commit, dispatch }, client) {
    return Vue.api.put("/clients/" + client.id, client).then(
      response => {
        if (response.status === 200) {
          let { data, error } = response.data;
          if (error) {
            dispatch("errorHandler", response);
          } else {
            commit("setClient", data);
          }
        } else {
          dispatch("errorHandler", response);
        }
        return response;
      },
      error => {
        dispatch("errorHandler", error);
        return error;
      }
    );
  }
};

const mutations = {
  insertClient(state, client) {
    state.clients.push(client);
  },
  removeClient(state, client) {
    let idx = state.clients.findIndex(o => {
      return o.id === client.id;
    });
    if (idx >= 0) {
      state.clients.splice(idx, 1);
    }
  },
  setClient(state, client) {
    state.client = client;
  },
  setClients(state, clients) {
    state.clients = clients;
  },
  setClientsProjects(state, clientsProjects) {
    state.clientsProjects = clientsProjects;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
