<template>
  <span
    class="icon avatar"
    :style="{ height: size + 'px', width: size + 'px' }"
  >
    <img v-if="user.imageUrl" :src="user.imageUrl" />
    <img v-else src="@/assets/profile.svg" alt="Avatar" />
  </span>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "user-avatar",
  props: {
    size: {
      type: Number,
      default: 36
    },
    user: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      signedAvatarPath: ""
    };
  },
  computed: {
    ...mapGetters(["cdn"])
  },
  mounted() {},
  methods: {
    ...mapActions(["fetchUpload"])
  }
};
</script>

<style lang="scss" scoped>
.avatar {
  background: whitesmoke;
  border: 1px solid whitesmoke;
  border-radius: 50%;
  height: 36px;
  overflow: hidden;
  width: 36px;
}
</style>
