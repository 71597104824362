import store from "@/store";

const Sessions = {
  eventType: "GET-SESSIONS",
  handle: function(event) {
    const sessions = JSON.parse(event.data);
    console.log(sessions);
    store.commit("insertSessions", sessions);
  }
};

export default Sessions;
