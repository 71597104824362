import { isEmpty } from "lodash/core";
import Vue from "vue";
import App from "./App.vue";

// 1st Party Libraries
import router from "./router";
import store from "./store";

// 3rd Party Libraries
import { abilitiesPlugin } from "@casl/vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { api } from "@/services/api";
import { sessionApi } from "@/services/session-api";
// import VueTour from "vue-tour";
import Tooltip from "vue-directive-tooltip";
import "vue-directive-tooltip/dist/vueDirectiveTooltip.css";
import { CoolSelectPlugin } from "vue-cool-select";
import "vue-cool-select/dist/themes/bootstrap.css";
import VueRouterBackButton from "vue-router-back-button";
import VueWorker from "vue-worker";
import VueSematext from "@/lib/vue-sematext";
import pdf from "vue-pdf";
import debounce from "lodash/debounce";

import "./filters";
import "./validators";

Vue.use(CoolSelectPlugin);
Vue.use(abilitiesPlugin, store.getters.ability);
// Vue.component("ValidationObserver", ValidationObserver);
// Vue.component("ValidationProvider", ValidationProvider);
Vue.use(Tooltip);
Vue.use(VueWorker);

// require("vue-tour/dist/vue-tour.css");

// Vue.use(VueTour);
Vue.use(VueRouterBackButton, { router, ignoreRoutesWithSameName: true });
Vue.use(VueSematext, {
  url: window._env_.VUE_APP_SEMATEXT_URL,
  token: window._env_.VUE_APP_SEMATEXT_TOKEN,
  tags: {
    environment: process.env.NODE_ENV,
    release: window._env_.VUE_APP_VERSION
  }
});

Vue.component("pdf", pdf);
Vue.pdf = Vue.prototype.$pdf = pdf;

Vue.api = Vue.prototype.$api = api;
Vue.sessionApi = Vue.prototype.$sessionApi = sessionApi;

Vue.config.devtools = process.env.NODE_ENV !== "production";
Vue.config.productionTip = false;

// Router Guard
router.beforeEach((to, from, next) => {
  debounce(() => store.commit("setRouteLoader", {show: true, text: "loading..."}), 1000);
  const authRequired = to?.meta?.authRequired === undefined
    ? true
    : to.meta.authRequired;
  if (isEmpty(store.getters.user)) {
    if (authRequired) {
      store.dispatch("fetchClaims").then(_user => {
        next();
      });
    } else {
      next();
    }
  } else {
    next();
  }

  /*
  let storedUser = localStorage.getItem("user");
  if (!isEmpty(storedUser) && isEmpty(store.state.user.user)) {
    storedUser = JSON.parse(storedUser);
    store.commit("setUser", storedUser);

    if (to.name === "home") {
      next("dashboard");
    } else {
      next();
    }
  } else {
    next();
  }
  */
});

router.afterEach((to, from) => {
  store.commit("setRouteLoader", {show: false, text: ""});
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
