import axios from "axios";
import * as rax from "retry-axios";
import router from "@/router";
import store from "@/store";

const api = axios.create({
  baseURL: window._env_.VUE_APP_API_URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    common: {},
    "Content-Type": "application/json"
  }
});

const MAX_REQUESTS_COUNT = 3;
const INTERVAL_MS = 10;
let PENDING_REQUESTS = 0;

/**
 * Axios Request Interceptor
 */
api.interceptors.request.use(function(config) {
  return new Promise((resolve, _reject) => {
    let interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS++;
        clearInterval(interval);
        resolve(config);
      }
    }, INTERVAL_MS);
  });
});

/**
 * Axios Response Interceptor
 */
api.interceptors.response.use(
  function(response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.resolve(response);
  },
  function(error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.reject(error);
  }
);

api.defaults.raxConfig = {
  instance: api,
  retry: 5,
  statusCodesToRetry: [[401, 401]],
  onRetryAttempt: err => {
    const cfg = rax.getConfig(err);
    if (cfg.currentRetryAttempt < 3) {
      return new Promise((resolve, reject) => {
        store
          .dispatch("refreshSilent")
          .then(response => {
            if (response.status === 200) {
              resolve();
            } else {
              reject();
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    } else {
      const authRequired = router?.currentRoute?.meta?.authRequired === undefined
        ? true
        : router.currentRoute.meta.authRequired;
      if (authRequired) {
        router.push("/login");
      }
    }
  }
};
rax.attach(api);

// const UNAUTHORIZED = 401;
// api.interceptors.response.use(
//   response => response,
//   error => {
//     const { status } = error.response;
//     if (status === UNAUTHORIZED) {
//       router.push("/login");
//     }
//     return Promise.reject(error);
//   }
// );

export { api };
