
const VueSematext = {
  install(Vue, options) {
    let strum = window["strum"];
    strum("identify", {
      name: options.tags.environment,
      identifier: options.tags.release,
      tags: options.tags
    });
    strum("config", {
      token: options.token,
      "receiverUrl": options.url
    });

    Vue.strum = strum;
    Vue.prototype.$strum = strum;
  }
};

export default VueSematext;
