import Handlers from "./handlers";

const eventSources = {
  eventSource: null,
  isConnected: false
};

eventSources.configureEventSources = () => {
  if (!eventSources.isConnected) {
    eventSources.eventSource = new EventSource(
      `${window._env_.VUE_APP_API_URL}eventstream`,
      { withCredentials: true }
    );
    for (const handler of Handlers) {
      eventSources.eventSource.addEventListener(handler.eventType, event => {
        handler.handle(event);
      });
    }
    eventSources.isConnected = true;
  }
};

eventSources.closeEventSource = () => {
  eventSources.eventSource.close();
  eventSources.isConnected = false;
};

export default eventSources;
