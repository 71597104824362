import moment from "moment-timezone";
import { isEmpty } from "lodash/core";
import Vue from "vue";

Vue.filter("date", dtstring => {
  return isEmpty(dtstring) ? "?" : moment(dtstring).format("MM/DD/YY");
});

Vue.filter("datetime", dtstring => {
  return isEmpty(dtstring)
    ? "?"
    : moment(dtstring).format("MM/DD/YY hh:mm a");
});

Vue.filter("time", dtstring => {
  return isEmpty(dtstring) ? "?" : moment(dtstring).format("hh:mm a");
});

Vue.filter("relativedate", dtstring => {
  return moment(dtstring).fromNow();
});

Vue.filter("underscoresToSpaces", string => {
  return typeof string === "string" ? string.replace("_", " ") : "";
});

Vue.filter("month", dtstring => {
  return isEmpty(dtstring) ? "?" : moment(dtstring).format("MMM");
});
Vue.filter("day", dtstring => {
  return isEmpty(dtstring) ? "" : moment(dtstring).format("D");
});

Vue.filter("monthyear", dtstring => {
  return isEmpty(dtstring) ? "No Date" : moment(dtstring).format("MMM YYYY");
});

Vue.filter("clients", session => {
  return isEmpty(session.clients)
    ? ""
    : session.clients.map(client => client.name).join(", ");
});

Vue.filter("project", session => {
  return isEmpty(session.project) ? "" : session.project.name;
});

Vue.filter("fullname", user => {
  if (user && !user.firstName && !user.lastName) return user.email;
  if (user && user.firstName == "undefined" && user.lastName == "undefined")
    return user.email;
  return !isEmpty(user) ? `${user.firstName} ${user.lastName}` : "";
});

export default {};
