<template>
  <div>
    <div class="menu-overlay" @click="hideMenu" v-if="mobileMenu"></div>
    <sidebar-menu
      class="side-navbar"
      :class="{ 'active-menu': mobileMenu == true }"
      :menu="updatedMenu"
      :width="width"
      @item-click="onItemClick"
      hideToggle
    >
      <div slot="header">
        <div id="logo">
          <router-link to="/dashboard/scheduled">
            <img alt="Site logo" src="../../../assets/logo.svg" />
          </router-link>
        </div>
      </div>
    </sidebar-menu>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { SidebarMenu } from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
export default {
  name: "sidebar-nav",
  components: {
    SidebarMenu
  },
  props: {},
  data() {
    return {
      mobileMenu: false,
      width: "220px",
      routeflag: false
    };
  },
  computed: {
    ...mapGetters(["sessions", "sessionsCounts"]),
    updatedMenu() {
      let menu = [
        {
          title: "Sessions",
          child: [
            {
              href: "/dashboard/scheduled",
              title: `Scheduled (${this.sessionsCounts.scheduled})`
            },
            {
              href: "/dashboard/unscheduled",
              title: `Unscheduled (${this.sessionsCounts.unscheduled})`
            },
            {
              href: "/dashboard/past",
              title: `Past (${this.sessionsCounts.past})`
            },
            {
              href: "/dashboard/archived",
              title: `Archived (${this.sessionsCounts.archived})`
            },
            {
              title: "Buy Sessions",
              class: "add-button"
            }
          ]
        },
        {
          title: "Projects",
          child: [
            {
              href: "/projects/a-z",
              title: "A - Z"
            },
            {
              href: "/projects/by-client",
              title: "By Client"
            },
            {
              href: "/projects/archived",
              title: "Archived"
            }
            /*{
                href: "/projects/add-project",
                title: "Add Project",
                class: "add-button",
                icon: {
                  element: "span",
                  class: "plus-icon"
                }
              }*/
            // {
            //   href: '/sessions-without-project',
            //   title: 'Sessions Without a Project',
            //   class: 'underline-menu mt-10'
            // }
          ]
        },
        {
          title: "Clients",
          child: [
            {
              href: "/clients/a-z",
              title: "A - Z"
            },
            {
              href: "/clients/archived",
              title: "Archived"
            }
            /* {
                href: "/clients/add-client",
                title: "Add Client",
                class: "add-button",
                icon: {
                  element: "span",
                  class: "plus-icon"
                }
              }*/
          ]
        },
        {
          title: "Tags",
          child: [
            {
              href: "/tags/a-z",
              title: "A - Z"
            },
            {
              href: "/tags/by-library",
              title: "By Library"
            }
          ]
        }
      ];
      if (this.mobileMenu) {
        menu = [
          ...menu,
          {
            title: "Account",
            child: [
              {
                href: "/account",
                title: "Profile"
              },
              {
                title: "Sign Out",
                class: "signout-button"
              }
            ]
          }
        ];
      }
      return menu;
    }
  },
  watch: {
    $route(to, from) {
      this.mobileMenu = false;
      if (this.$route.name != "dashboard") {
        this.fetchSessionsCounts();
      }
      if (this.$route.path === "/dashboard/unscheduled") {
        this.routeflag = true;
      } else {
        this.routeflag = false;
      }
    }
  },
  mounted() {
    this.$root.$on("openMobileMenu", _value => {
      // here you need to use the arrow function
      this.mobileMenu = true;
    });
    this.$root.$on("closeMobileMenu", _value => {
      this.mobileMenu = false;
    });
  },
  methods: {
    ...mapActions(["purchaseSessions", "logout", "fetchSessionsCounts"]),
    onItemClick(event, item) {
      if (event.type === "click") {
        let itemData = JSON.parse(JSON.stringify(item));
        if (
          itemData.class === "add-button" &&
          itemData.title === "Buy Sessions"
        ) {
          if (this.routeflag) {
            this.purchaseSessions(true);
          } else {
            this.purchaseSessions(false);
          }
        }
        if (
          itemData.class === "signout-button" &&
          itemData.title === "Sign Out"
        ) {
          this.logout();
        }
      }
    },
    hideMenu() {
      this.mobileMenu = false;
      this.$root.$emit("closeMobileMenu", this.mobileMenu);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#logo {
  padding: 12px 15px 12px 38px;
}
#logo img {
  max-width: 120px;
  width: 100%;
}
@media only screen and (max-width: 1023px) {
  #logo {
    padding: 12px 15px 12px 70px;
  }
}
</style>
